import React , {useEffect} from "react";
import "../assets/css/homeAfter.css";
import glb from "../1.vana.glb";
import glbTwo from "../2.vanalar.glb";
import focus1 from "../assets/img/factory.png";
import focus2 from "../assets/img/uclu.png";
import focus3 from "../assets/img/detay_1.png";
import focus4 from "../assets/img/detay_2.png";
import focus5 from "../assets/img/k_detay_1.png";
import focus6 from "../assets/img/cover.png";
import product1 from "../assets/img/focus_r.svg";
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom";

const HomeAfterComponent = () => {
  const languageData = useSelector(state => state.data);
  const currentLanguage = useSelector(state => state.language)
  const language = useSelector(state => state.language);
  
  const {language:x} =useParams()
  useEffect(()=> {
    if (x && !["en","fr","ru","tr"].includes(x)) {
      window.location.pathname = `/404`;
    } else if (x && window.location.pathname.endsWith("/")) {
      const newPathname = window.location.pathname.slice(0, -1);
      window.location.pathname = newPathname;
    }
  }, [x]);

  return (
    <>
      <div
        className="d-flex x-scroll justify-content-center align-items-center flex-column overflow-hidden position-relative"
        
      >
        <div className="black-background mt-5 pb-4">
          <div className="container d-lg-flex d-md-flex justify-content-center align-items-center flex-column mt-5">
            <div className="col-12 mb-5 home-viewer-text d-lg-flex d-md-flex">
              <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center flex-column">
                <model-viewer src={glb} camera-controls></model-viewer>
                <div
                  className="d-flex align-items-center"
                  style={{ width: "max-content" }}
                >
                  <img style={{ height: "4vh" }} src={product1} alt="focus actuators"></img>
                  <span className="ms-2">
                    <b> {languageData.e_series}</b>
                  </span>
                </div>
                <p className="text-white">
                {languageData.on_off_multi_turn_electric_actuator}
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-12 m-5 d-flex justify-content-center align-items-center flex-column">
                <model-viewer src={glbTwo} camera-controls></model-viewer>
                <div
                  className="d-flex align-items-center"
                  style={{ width: "max-content" }}
                >
                  <img style={{ height: "4vh" }} src={product1} alt="focus actuators"></img>
                  <span className="ms-2">
                    <b>{languageData.e_icu_series_}</b>
                  </span>
                </div>
                <p className="text-white p-0 m-0">
                {languageData.modulating_multi_turn_electric_actuator}
                </p>
                <p className="text-white p-0 m-0">
                {languageData.with} <span>{languageData.I}</span>{languageData.ntegral} <span>{languageData.C}</span>{languageData.ontrol} <span>{languageData.U}</span>
                {languageData.nit} <span>+</span>{languageData.lcd_display}
                </p>
              </div>
            </div>
            <a href= {`/products/${language}`}   rel="noreferrer" target="_blank"
           
                                      aria-label="products">
              <p className="button-focus">{languageData.all_products}</p>
            </a>

            <div className="row d-flex justify-content-center">
              <div className="col-lg-12   col-md-12 col-3 mt-5 d-lg-flex d-md-flex text-white bg-gray">
                <div className="col-lg-6 col-md-6 col-12 p-3 d-flex flex-column justify-content-center">
                  <p>
                  {languageData.home_text_2}
                  </p>
                  <a href= {`/contact/${language}`}   rel="noreferrer" target="_blank"
                                      aria-label="contact-main">
                    <p className="button-focus mt-3">{languageData.contact_2}</p>
                  </a>
                </div>

                <div className="col-lg-6 col-md-6 col-12">
                  <img className="w-100 b_radius" src={focus1} alt="focus actuators"></img>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12   col-md-12 col-3 mt-3 d-flex text-white bg-gray justify-content-center flex-column">
                <h2 className="p_stroke text-center">{languageData.focus_on_the_solution}</h2>
                <p className="p-3">
                {languageData.home_text_3}
                </p>

                <i className="p-3 text-center">
                {languageData.home_title_1}
                </i>

                <div className="d-flex justify-content-center w-100">
                  <img className="w-100 b_radius" src={focus2} alt="focus actuators"></img>
                </div>
                <div className=" mt-5 d-lg-flex d-md-flex text-white ">
                  <div className="col-lg-6 col-md-6 col-12 p-3 d-flex justify-content-center flex-column">
                    <p>
                    {languageData.home_text_4}
                    </p>
                    <br />
                    <ul className="ms-3">
                      <li>{languageData.water_wastewater} </li>
                      <li>{languageData.Industry} </li>
                      <li>{languageData.energy} </li>
                    </ul>
                    {languageData.to_see_all_the_application_areas} 
                    <a target="_blank " href= {`/industries/${language}`}  aria-label="ind">
                      <p className="button-focus mt-3">{languageData.industries}</p>
                    </a>
                  </div>
                 
                  <div className="col-lg-6 col-md-6 col-12 ">
                    <img className="w-100 b_radius" src={focus3} alt="focus actuators"></img>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-center align-items-center flex-column overflow-hidden bg-black position-relative mobile-top"
        style={{ zIndex: 99 }}
      >
        <div className="white-background mt-3 d-flex justify-content-center">
          <div className="col-lg-8 col-md-7 d-flex justify-content-center align-items-center flex-column">
            <div className="row w-100 d-flex justify-content-center">
              <div className="col-lg-10 col-md-12 col-6 white-margin d-lg-flex d-md-flex text-white justify-content-around">
                <div className="col-lg-7 col-md-6 col-12 p-3 m-1 bg-gray d-flex align-items-center">
                  <h2 className="p_stroke p-3">{languageData.home_title_2}</h2>
                </div>

                <div className="col-lg-5 col-md-5 col-12 p-3 m-1 bg-gray">
                  <img className="w-100 b_radius" src={focus4} alt="focus actuators"></img>
                </div>
              </div>
            </div>
            <div className="row w-100 d-flex justify-content-center">
              <div className="col-lg-10 col-md-12 col-6 mt-lg-3 mt-1 d-lg-flex d-md-flex text-white justify-content-around">
                <div className="col-lg-6 col-md-6 col-12 p-4 bg-gray m-1 d-flex align-items-center">
                  <p className="m-0 p-0 text-white">
                  {languageData.home_text_5}
                  </p>
                </div>

                <div className="col-lg-6 col-md-5 col-12 p-3 bg-gray m-1 d-flex align-items-center">
                  <img className="w-100 b_radius" src={focus5} alt="focus actuators"></img>
                </div>
              </div>
            </div>
            <div className="row w-100 d-flex justify-content-center">
              <div className="col-lg-10 col-md-12 col-6 mt-lg-3 mt-1 d-lg-flex d-md-flex text-white justify-content-around">
                <div className="col-12  bg-gray m-1 d-flex align-items-center">
                  <img className="w-100 b_radius" src={focus6} alt="focus actuators"></img>
                </div>
              </div>
            </div>
{/* 
            <div className="row w-100 d-flex justify-content-center">
              <div className="col-lg-10 col-md-12 col-6 mt-lg-3 mt-1 d-lg-flex d-md-flex text-white justify-content-around">
                <div className="col-12 text-black m-1 d-flex align-items-center">
                <Accordion preExpanded={["0"]} >
                      {homefaqData.homefaqs.map((faq, index) => (
                        <AccordionItem key={faq.faq_no} uuid={`${index}`}>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              {currentLanguage == 'en' ? faq.question_en : faq.question_fr}
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>{currentLanguage == 'en' ? faq.answer_en : faq.answer_fr}</p>
                            {faq.button === "true" && (
                              <a rel="noreferrer" aria-label="faq" target="_blank" href={`${faq.btn_href}`}>
                                <p className="button-focus mt-2">
                                {currentLanguage === 'en' ? faq.btn_text_en : faq.btn_text_fr}
                                </p>
                              </a>
                            )}
                          </AccordionItemPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                </div>
              </div>
            </div> */}
          </div>
        
        </div>

       
      </div>
     
    </>
  );
};

export default HomeAfterComponent;
