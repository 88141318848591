import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/reducers/store';
import './index.css';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";

const providerElement = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(providerElement, document.getElementById('root'));
