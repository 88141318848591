import React, { useEffect, useState } from "react";
import "../assets/css/about.css";
import Fade from "react-reveal/Fade";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Footer from "../wrappers/Footer";
import Loader from "../wrappers/Loader";
import img1 from "../assets/img/why_focus/why_focus_1.png";
import img2 from "../assets/img/why_focus/right_cost.png";
import img3 from "../assets/img/why_focus/swift.png";
import Header from "../wrappers/Header";
import img4 from "../assets/img/why_focus/premium_quality_3.png"
import img5 from "../assets/img/why_focus/premium_quality_2.png"
import {Helmet} from "react-helmet";
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom";

const WhyFocus = () => {
  const languageData = useSelector(state => state.data);
  const language = useSelector(state => state.language);
  const [showMenu, setShowMenu] = useState(false);
  const [showFooter, setShowFooter] = useState(true);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
    setShowFooter(showMenu);
  };
  const url = `https://www.focusactuators.com/why_focus/${language}`;

  const {language:x} =useParams()
  useEffect(()=> {
    if (x && !["en","fr","ru","tr"].includes(x)) {
      window.location.pathname = `/404`;
    } else if (x && window.location.pathname.endsWith("/")) {
      const newPathname = window.location.pathname.slice(0, -1);
      window.location.pathname = newPathname;
    }
  }, [x]);

  return (
    <>
    <Helmet>
    
        <title>{languageData.why_focus_helmet}</title>
        <meta name="description" content="Over the decades, we have honed our expertise and perfected our craft so have become well-known in global industry."/>
        <link
          rel="canonical"
          href={url}
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Loader />
      <Fade top delay={800}>
        <div className="downing-page-div">
          <Header showMenu={showMenu} toggleMenu={toggleMenu} />
          <div className="container-fluid text-header d-flex justify-content-center  flex-column align-items-center">
            {showFooter && (
              <>
                <div className="col-12 title-sticky">
                  <h1 className="title-main">{languageData.WHY_FOCUS}</h1>
                </div>
                <div className="container bg-main">
               
                    <div className="col-lg-12 col-12 bg-gray text-white mt-2">
                      <Tabs>
                        <TabList>
                          <Tab>{languageData.premium_quality}</Tab>
                          <Tab>{languageData.right_cost}</Tab>
                          <Tab>{languageData.swift_manufacturing}</Tab>
                        </TabList>

                        <TabPanel>
                          <div className="d-lg-flex d-md-flex">
                            <Fade left>
                              <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center p-5">
                                <p className="m-0 p-0">
                                {languageData.whypage_text_1}
                                </p>
                              </div>
                              <div className="col-lg-5 col-md-5 col-12 offset-lg-1 offset-md-1">
                                <img className="b_radius w-100" src={img1} alt="focus actuators"/>
                              </div>
                            </Fade>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="d-lg-flex d-md-flex">
                            <Fade left>
                              <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center p-5">
                                <p>
                                {languageData.whypage_text_2}
                                </p>
                              </div>
                              <div className="col-lg-5 col-md-5 col-12 offset-lg-1 offset-md-1">
                                <img className="b_radius w-100" src={img2} alt="focus actuators"/>
                              </div>
                            </Fade>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="d-lg-flex d-md-flex">
                            <Fade left>
                              <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center p-5">
                                <p>
                                {languageData.whypage_text_3}
                                </p>
                              </div>
                              <div className="col-lg-5 col-md-5 col-12 offset-lg-1 offset-md-1">
                                <div></div>
                                <img className="b_radius w-100" src={img3} alt="focus actuators"/>
                              </div>
                            </Fade>
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                

                  <div className="col-lg-12 col-12 d-lg-flex d-md-flex gap-3 justify-content-center">
                    <div className="col-lg-6 col-md-6 col-12 mt-4">
                      <div className="bg-gray text-white p-5">
                        <p className="m-0 p-0">
                        {languageData.whypage_text_4}
                        </p>
                      </div>
                      <div className="bg-gray text-white mt-4 d-flex align-items-center justify-content-center">
                        <img className="b_radius" src={img4} alt="focus actuators"></img>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 mt-4 d-flex justify-content-between flex-column">
                      <div className="bg-gray text-white d-flex align-items-center justify-content-center">
                       <img className="b_radius" src={img5} alt="focus actuators"></img>
                      </div>
                      <div className="bg-gray text-white mt-4 p-5 d-flex align-items-center justify-content-center">
                        <p className="p-0 m-0">
                        {languageData.whypage_title_1} <br />
                          <br />
                          {languageData.whypage_text_5}
                        </p>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-12 bg-gray text-white mb-5 mt-4 ">
                    <p style={{ fontStyle: "italic" }}>
                    {languageData.whypage_title_2}{" "}
                      <br />
                      <br />  {languageData.whypage_text_6}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
          {showFooter && <Footer />}
        </div>
      </Fade>
    </>
  );
};

export default WhyFocus;
