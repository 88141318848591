import React, { useEffect, useState } from "react";
import "../assets/css/home.css";
import Fade from "react-reveal/Fade";
import Header from "../wrappers/Header";
import HomeAfterComponent from "./HomeAfterComponent";
import Footer from "../wrappers/Footer";
import "../assets/css/about.css";
import { Swiper, SwiperSlide } from "swiper/react";
import slider1 from "../assets/img/slider/cover_01.webp";
import slider2 from "../assets/img/slider/cover_02.webp";
import slider3 from "../assets/img/slider/cover_03.webp";
import focus_logo from "../assets/img/focus_r.svg";
import "swiper/css";
import {Helmet} from "react-helmet";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import MainLoader from "../wrappers/MainLoader";
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom";

const HomeTwoComponent = () => {
  const languageData = useSelector(state => state.data);
  const language = useSelector(state => state.language);
  const [showMenu, setShowMenu] = useState(false);
  const [showFooter, setShowFooter] = useState(true);
  const [showMain, setShowMain] = useState(false);

  const url = `https://www.focusactuators.com/${language}`;


  const {language:x} =useParams()
  useEffect(()=> {
    if (x && !["en","fr","ru","tr"].includes(x)) {
      window.location.pathname = `/404`;
    } else if (x && window.location.pathname.endsWith("/")) {
      const newPathname = window.location.pathname.slice(0, -1);
      window.location.pathname = newPathname;
    }
  }, [x]);

//   useEffect(() => {
//   if (!["en", "fr", "ru", "tr"].includes(x)) {
//     window.location.pathname = `/404`;
//   }
// }, [x]);


  const toggleMenu = () => {
    setShowMenu(!showMenu);
    setShowFooter(showMenu);
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowMain(true);
    }, 2200);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  return (
    <>
    <Helmet>
      <title>{languageData.focus_actuators}</title>
        <meta name="description" content="Focus actuators are mechanisms designed to control the focus of optical components, such as lenses or mirrors, within various technological systems."/>
        <link
          rel="canonical"
          href={url}
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <MainLoader />
      {showMain && (
        <Fade top>
          <div className="downing-page-div homepage-main">
            <Header showMenu={showMenu} toggleMenu={toggleMenu} />
            {showFooter && (
              <div className="col-12 d-flex justify-content-center flex-column align-items-center">
                <Swiper
                  navigation={true}
                  loop={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper "
                >
                  <SwiperSlide>
                    <img src={slider3} alt="focus actuators"></img>
                    <div className="d-flex justify-content-center swiper-text-1 align-items-center flex-column">
                      <Fade top>
                        <h3 className="slider-text-main p-0 m-0">{languageData.now_featuring}</h3>
                      </Fade>

                      <Fade bottom>
                        <img
                          className="img-slider-main mt-lg-1 mt-3"
                          src={focus_logo}
                          alt="focus actuators"
                        ></img>
                      </Fade>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={slider2} alt="focus actuators"></img>
                    <div className="d-flex justify-content-center swiper-text-1 align-items-center flex-column">
                      <Fade top>
                        <h2 className="text-white">
                        {languageData.range_of_multi_turn_quarter_turn}
                          <br />
                          {languageData.electric_actuators}
                        </h2>
                      </Fade>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={slider1} alt="focus actuators"></img>
                    <div className="d-flex justify-content-center align-items-end swiper-text-2  flex-column">
                      {" "}
                      <Fade left>
                        <h2 className="text-white">{languageData.proudly_made_in_Turkiye}</h2>{" "} </Fade>
                    <Fade right><p
                          style={{ fontSize: "1.3rem" }}
                          className="text-white "
                        >
                         {languageData.since_1986}
                        </p></Fade>    
                     
                    </div>
                  </SwiperSlide>
                </Swiper>

                <div className="mt-5">
                  <div className="d-flex x-scroll justify-content-center align-items-center flex-column overflow-hidden">
                    <div className="col-lg-4 col-md-4 col-3 mt-5  d-flex justify-content-start container-after flex-column">
                    <h1 className="d-none"> {languageData.focus_actuators}</h1>  <h2 className="stroke_denz"> {languageData.creatively_reimagined}</h2>
                      <p>
                        <b>
                        {languageData.home_text_1}
                        </b>
                      </p>
                      <a href= {`/about_focus/${language}`} target="_blank" aria-label="about">
                     
                        <p className="button-focus"> {languageData.about_us}</p>
                      </a>
                    </div>
                  </div>

                  <HomeAfterComponent />
                  <div className="d-flex justify-content-center">
                    <div className="col-lg-12 col-md-12 col-4 padding-home-footer mt-3">
                      <Footer />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Fade>
      )}
    </>
  );
};

export default HomeTwoComponent;
