import React, { useState , useEffect } from "react";
import "../assets/css/about.css";
import Fade from "react-reveal/Fade";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Footer from "../wrappers/Footer";
import Header from "../wrappers/Header";
import Loader from "../wrappers/Loader";
import img1 from "../assets/img/about/history.png";
import img2 from "../assets/img/about/production.png";
import img3 from "../assets/img/about/our_approuch.png";
import about1 from "../assets/img/about/about_us.png";
import about2 from "../assets/img/about/about_us_2.png";
import {Helmet} from "react-helmet";
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom";

const AboutComponent = () => {
  const languageData = useSelector(state => state.data);
  const language = useSelector(state => state.language);
  const [showMenu, setShowMenu] = useState(false);
  const [showFooter, setShowFooter] = useState(true);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
    setShowFooter(showMenu);
  };

  const url = `https://www.focusactuators.com/about_focus/${language}`;


  const {language:x} =useParams()
  useEffect(()=> {
    if (x && !["en","fr","ru","tr"].includes(x)) {
      window.location.pathname = `/404`;
    } else if (x && window.location.pathname.endsWith("/")) {
      const newPathname = window.location.pathname.slice(0, -1);
      window.location.pathname = newPathname;
    }
  }, [x]);
  return (
    <>
    <Helmet>
        <title>{languageData.about_us_helmet}</title>
        <meta name="description" content="Focus is a sister company of DENZ Water Technologies. For more click to read!"/>
        <link
          rel="canonical"
          href={url}
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Loader />
      <Fade top delay={800}>
        <div className="downing-page-div">
          <Header showMenu={showMenu} toggleMenu={toggleMenu} />
          <div className="text-header d-flex justify-content-center  flex-column align-items-center">
            {showFooter && (
              <div className="container">
                <div className="col-12 title-sticky">
                  <h1 className="title-main">{languageData.about_us}</h1>
                </div>
                <div className="bg-main"><div className="col-lg-12 col-12 d-lg-flex d-md-flex gap-3">
                  <div className="col-lg-6 col-md-6 col-12 p-1 d-flex justify-content-between flex-column">
                    <div className="bg-gray text-white p-5">
                      <p className="p-0 m-0">
                       {languageData.aboutus_text_1}
                      </p>
                    </div>
                    <div className="bg-gray text-white mt-4 d-flex justify-content-center">
                      <img alt="focus actuators" src={about1}></img>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12 p-1 flex-column d-flex justify-content-between">
                    <div className="bg-gray text-white d-flex justify-content-center">
                      <img alt="focus actuators" src={about2}></img>
                    </div>
                    <div className="bg-gray text-white mt-4 p-5">
                      <p className="m-0 p-0">
                      {languageData.aboutus_text_2}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-12 bg-gray text-white p-5 mt-4">
                  <p className="p-0 m-0" style={{ fontStyle: "italic" }}>
                  {languageData.aboutus_text_3}
                  </p>
                  <p>
                  {languageData.aboutus_text_4}
                  </p>
                </div>
                <Fade>
                  <div className="col-lg-12 col-12 bg-gray text-white  mb-5 p-5 mt-4">
                    <Tabs>
                      <TabList>
                        <Tab> {languageData.history}</Tab>
                        <Tab>{languageData.production}</Tab>
                        <Tab>{languageData.our_approach}</Tab>
                      </TabList>

                      <TabPanel>
                        <div className="d-lg-flex d-md-flex">
                          <Fade left>
                            <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center">
                              <p>
                                {languageData.aboutus_text_5}
                              </p>
                            </div>
                            <div className="col-lg-5 col-md-5 col-12 offset-lg-1 offset-md-1">
                              <img alt="focus actuators" className="b_radius w-100" src={img1} />
                            </div>
                          </Fade>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="d-lg-flex d-md-flex">
                          <Fade left>
                            <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center">
                              <p>
                              {languageData.aboutus_text_6}
                              </p>
                            </div>
                            <div className="col-lg-5 col-md-5 col-12 offset-lg-1 offset-md-1">
                              <img alt="focus actuators" className="b_radius w-100" src={img2} />
                            </div>
                          </Fade>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="d-lg-flex d-md-flex">
                          <Fade left>
                            <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center">
                              <p>
                              {languageData.aboutus_text_7}
                              </p>
                            </div>
                            <div className="col-lg-5 col-md-5 col-12 offset-lg-1 offset-md-1">
                              <img alt="focus actuators" className="b_radius w-100" src={img3} />
                            </div>
                          </Fade>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </Fade>
                <div className="col-lg-12 col-12 bg-gray text-white p-5 mt-4 mb-5">
                  <p>
                    <h3 className="p_stroke text-start">
                      <b> {languageData.brand_manifesto}</b>
                    </h3>

                    <br />
                    <br />
                    <p>
                      <b>{languageData.who_we_are}</b>
                    </p>
                    <p>
                    {languageData.aboutus_text_8}
                    </p>
                    <p>
                      <b> {languageData.our_mission}</b>
                    </p>
                    <p>
                      {" "}
                      {languageData.aboutus_text_9}
                    </p>

                    <p>
                      <b> {languageData.our_vision_and_goals}</b>
                    </p>

                    <p>
                      {" "}
                      {languageData.aboutus_text_10}
                    </p>

                    <p>
                      <b> {languageData.target_audience}</b>
                    </p>

                    <p>
                      {" "}
                      {languageData.aboutus_text_11}
                    </p>

                    <p>
                      <b> {languageData.differentiating_features}</b>
                    </p>

                    <p>
                      {" "}
                      {languageData.aboutus_text_12}
                    </p>

                    <p>
                      <b> {languageData.brand_personality}</b>
                    </p>

                    <p>
                      {" "}
                      {languageData.aboutus_text_13}
                    </p>

                    <p>
                      <b> {languageData.customer_experience_and_relationships}</b>
                    </p>

                    <p>
                      {" "}
                      {languageData.aboutus_text_14}
                    </p>

                    <p>
                      <b>  {languageData.social_responsibility_and_sustainability}</b>
                    </p>

                    <p>
                      {" "}
                      {languageData.aboutus_text_15}
                    </p>
                  </p>
                </div></div>
                
              </div>
            )}
          </div>
          {showFooter && <Footer />}
        </div>
      </Fade>
    </>
  );
};

export default AboutComponent;
