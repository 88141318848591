import React from "react";
import "../assets/css/loader.css";
import Fade from "react-reveal/Fade";

const Loader = () => {
  return (
    <Fade top>
      <div className="loader">
        <div className="loder-images"></div>
      </div>
    </Fade>
  );
};

export default Loader;
