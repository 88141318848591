import React, { useState, useEffect } from "react";
import "../assets/css/products.css";
import Header from "../wrappers/Header";
import Fade from "react-reveal/Fade";
import Footer from "../wrappers/Footer";
import Loader from "../wrappers/Loader";
import "../assets/css/about.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "../assets/css/documents.css";
import general from "../assets/img/general.png";
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import productsData from "../data/products.json";
import { Link } from "react-router-dom";

const ProductDetailComponent = () => {
  const languageData = useSelector(state => state.data);
  const [showMenu, setShowMenu] = useState(false);
  const currentLanguage = useSelector(state => state.language)
  const language = useSelector(state => state.language);
  const url = `https://www.focusactuators.com/products/${language}`;
  const { language: x } = useParams()
  useEffect(() => {
    if (x && !["en", "fr", "ru", "tr"].includes(x)) {
      window.location.pathname = `/404`;
    } else if (x && window.location.pathname.endsWith("/")) {
      const newPathname = window.location.pathname.slice(0, -1);
      window.location.pathname = newPathname;
    }
  }, [x]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  function removeTurkishChars(text) {
    const turkishChars = {
      ğ: "g",
      Ğ: "G",
      ü: "u",
      Ü: "U",
      ş: "s",
      Ş: "S",
      ı: "i",
      İ: "I",
      ö: "o",
      Ö: "O",
      ç: "c",
      Ç: "C",
    };

    return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]);
  }

  return (
    <>
      <Helmet>
        <title>{languageData.limitors_helmet}</title>
        <meta name="description" content="You may find all focus documentation in this section including product catalogues and other." />
        <link
          rel="canonical"
          href={url}
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Loader />
      <Fade top delay={800}>
        <div className="downing-page-div documents-main">
          <Header showMenu={showMenu} toggleMenu={toggleMenu} />
          <div className="container text-header d-flex justify-content-center flex-column">
            {!showMenu && (
              <>
                <div className="title-sticky">
                  <h1 className="title-main">{languageData.limitors}</h1>
                </div>
                <div className="products-page-inner">  <div className="row mb-5 ">
                  {/* <p className="col-lg-6 col-12 text-black d-flex align-items-center">
                  {languageData.doc_text_1}
                  </p> */}

                  {/* <div className="col-lg-6 col-12 d-flex justify-content-end">
                    <img src={general} alt="focus actuators" />
                  </div> */}
                </div>


                  <div className="col-lg-9 col-md-12 col-12 text-white mt-2 mb-5 w-100">
                    <Tabs>
                      {/* <TabList>
                        <Tab>{languageData.products}</Tab>
                        <Tab>{languageData.general}</Tab>
                        <Tab>{languageData.certificates}</Tab>
                      </TabList> */}

                      <TabPanel>
                        <div className="col-12  ">
                          <div className="d-flex flex-wrap ">
                            {productsData.map((product) => (
                              <div
                                key={product.products_no}
                                className="col-xl-4 col-lg-6 col-12 mb-5"
                              >
                                <div className="bg-gray text-white m-2 h-100">
                                  <div className="w-100 documents-img mb-4 bg-black d-flex align-items-center justify-content-center b_radius ">
                                    {product.img !== "" ? (
                                      <img
                                        src={product.img}
                                        alt="Document"
                                      />
                                    ) : (
                                      <p></p>
                                    )}
                                  </div>
                                  <div className=" w-100 justify-content-between ">
                                    <div>
                                      {" "}
                                      <h4>{currentLanguage === 'en' ? product.title :
                                        (currentLanguage === 'fr' ? product.title_fr :
                                          (currentLanguage === 'ru' ? product.title_ru :
                                            (currentLanguage === 'tr' ? product.title_tr : product.title)))}</h4>
                                    </div>

                                    <Link
                                      aria-label="document"
                                      className="doc_btn"
                                      to={`/${removeTurkishChars(product.title)
                                        .replace(/\s+/g, "-")
                                        .toLowerCase()}/${language}`}
                                    >
                                      <p
                                        data-hover="download"
                                        className="doc_btn_content button-focus m-0"
                                      >
                                        {languageData.details}
                                      </p>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </TabPanel>


                    </Tabs>

                    {/* <div className="row w-100 d-flex justify-content-center">
                      <div className="col-lg-12 col-md-12 col-12 mt-lg-3 mt-1 d-lg-flex d-md-flex text-white justify-content-around">
                        <div className="col-12 d-flex flex-column bg-gray m-1 d-flex align-items-center">
                          <div className="text-white">
                            <h3 >{languageData.what_is_multi_turn_actuators_title}</h3>
                            <p>{languageData.what_is_multi_turn_actuators_desc_1}</p>
                            <p>{languageData.what_is_multi_turn_actuators_desc_2}</p>

                          </div>
                          <div className="text-white">
                            <h3>{languageData.what_is_multi_turn_electric_actuators_title}</h3>
                            <p>{languageData.what_is_multi_turn_electric_actuators_desc_1}</p>
                            <p>{languageData.what_is_multi_turn_electric_actuators_desc_2}</p>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

              </>
            )}
          </div>
          {!showMenu && <Footer />}
        </div>
      </Fade>
    </>
  );
};

export default ProductDetailComponent;
