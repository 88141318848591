import { createStore } from 'redux';
import languageReducer from './reducer';

import englishData from '../../Translation/English.json';
import frenchData from '../../Translation/French.json';
import russianData from '../../Translation/Russian.json';
import turkishData from '../../Translation/Turkish.json';

const defaultLanguage = 'en';

const getLanguageData = (language) => {
  switch (language) {
    case 'fr':
      return frenchData;
    case 'ru':
      return russianData;
    case 'tr':
      return turkishData;
    default:
      return englishData;
  }
};

const urlLanguage = window.location.pathname.split('/').find(part => ['en', 'fr', 'ru', 'tr'].includes(part)) || defaultLanguage;

const initialState = {
  language: urlLanguage,
  data: getLanguageData(urlLanguage)
};

const store = createStore(languageReducer, initialState);

// Dil değiştiğinde localStorage'a kaydedin ve URL'yi güncelleyin
store.subscribe(() => {
  const state = store.getState();
  localStorage.setItem('language', state.language);
  updateLanguageInURL(state.language);
});

// Dilin URL'sini güncelleme fonksiyonu
// export function updateLanguageInURL(language) {
//   const currentPath = window.location.pathname;
//   const parts = currentPath.split('/');
//   const languageIndex = parts.findIndex(part => ['en', 'fr', 'ru', 'tr'].includes(part));
//   if (languageIndex !== -1) {
//     parts[languageIndex] = language;
//   } else {
//     parts.push(language);
//   }
//   const newPath = parts.join('/');
//   window.history.replaceState(null, null, newPath);
// }

export function updateLanguageInURL(language) {
  const currentPath = window.location.pathname;
  let newPath = currentPath.replace(/\/$/, ''); // Sonundaki gereksiz '/' karakterini kaldır
  const parts = newPath.split('/');
  const languageIndex = parts.findIndex(part => ['en', 'fr', 'ru', 'tr'].includes(part));
  if (languageIndex !== -1) {
    parts[languageIndex] = language;
  }
  //  else {
  //   parts.push(language);
  // }
  newPath = parts.join('/');
  window.history.replaceState(null, null, newPath);
}


// Dil seçimi yapıldığında URL'nin güncellenmesi
export const handleLanguageChange = (language) => {
  store.dispatch({ type: 'CHANGE_LANGUAGE', payload: language });
  updateLanguageInURL(language);
};

// Sayfa yenilendiğinde URL'den dil parametresini okuyarak dil durumunu güncelleme
export const handleInitialLanguage = () => {
  const urlLanguage = window.location.pathname.split('/').find(part => ['en', 'fr', 'ru', 'tr'].includes(part)) || defaultLanguage;
  store.dispatch({ type: 'CHANGE_LANGUAGE', payload: urlLanguage });
};
handleInitialLanguage();

export default store;
