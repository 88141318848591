import React from "react";
import "../assets/css/footer.css";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineLinkedin } from "react-icons/ai";
import { AiOutlineFacebook } from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";
import { BsFillTelephoneFill } from "react-icons/bs";
import { SlEnvolopeLetter } from "react-icons/sl";
import osilogo from "../assets/img/osicrew.svg";
import footerLogo from "../assets/img/logo_white.png";
import "../assets/css/footer.css";
import { MdFactory } from "react-icons/md";
import { useSelector } from 'react-redux'


const Footer = () => {
  const languageData = useSelector(state => state.data);
  const language = useSelector(state => state.language); 

  return (
    <div>
      <div className="container d-flex justify-content-center mb-3">
        <div className="col-lg-12   footer-bg p-5">
          <div className="row">
          <a title={languageData.focus_actuators_1} href={`/${language}`}><img style={{width:"auto"}} src={footerLogo} alt="focus actuators"></img></a>
              
           
            <div className="col-lg-12 footer-main p-0 align-items-center  ">
              <div className="col-lg-3 col-md-3 col-12 d-flex">
                <ul className="text-footer">
                  <li>
                    <a title={languageData.home} href={`/${language}`} aria-label="home-footer">
                    {languageData.home}
                    </a>
                  </li>
                  <li>
                    <a title={languageData.why_focus} href={`/why_focus/${language}`} aria-label="why-focus-footer">
                    {languageData.why_focus}
                    </a>
                  </li>
                  <li>
                    <a title={languageData.about_focus} href={`/about_focus/${language}`} aria-label="about-footer">
                    {languageData.about_focus}
                    </a>
                  </li>
                  <li>
                    <a title={languageData.industries} href={`/industries/${language}`} aria-label="ind-footer">
                    {languageData.industries}
                      
                    </a>
                  </li>
                  <li>
                    <a title={languageData.products} href={`/products/${language}`} aria-label="products-footer">
                    {languageData.products}
                    </a>
                  </li>
                  <li><a title={languageData.multiturn_electric_actuators} href={`/multi-turn_electric_actuators/${language}`}>{languageData.multiturn_electric_actuators}</a></li>
                            <li><a title={languageData.quarterturn_electric_actuators} href={`/quarter-turn_electric_actuators/${language}`}>{languageData.quarterturn_electric_actuators}</a></li>
                            <li><a title={languageData.gearboxes} href={`/bevel-gearboxes/${language}`}>{languageData.gearboxes}</a></li>
                  <li>
                    <a title={languageData.service_support} href={`/service_support/${language}`} aria-label="service-footer">
                    {languageData.service_support}
                      
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-3 col-12 d-flex ">
                <ul className="text-footer">
                  <li>
                    <a title={languageData.FAQ} href={`/faq/${language}`} aria-label="faq-footer">
                    {languageData.FAQ}
                    </a>
                  </li>
                  <li>
                    <a title={languageData.documents_2} href={`/documents/${language}`} aria-label="docs-footer">
                    {languageData.documents_2}
                      
                    </a>
                  </li>
                  <li>
                    <a title={languageData.human_resources} href={`/human_resources/${language}`} aria-label="human-footer">
                    {languageData.human_resources}
                      
                    </a>
                  </li>
                  <li>
                    <a title={languageData.become_a_distributor} href={`/become_a_distributor/${language}`} aria-label="become-footer">
                    {languageData.become_a_distributor}
                      
                    </a>
                  </li>
                  <li>
                        <a title={languageData.news_menu} href={`/news/${language}`} aria-label="news-footer">{languageData.news_menu}</a>
                      </li>

                      <li>
                        <a title={languageData.blog_menu} href={`/blog/${language}`} aria-label="blog-footer">{languageData.blog_menu}</a>
                      </li>

                  <li>
                  
                        <a title={languageData.termsandconditional} href={`/salesterms/${language}`} aria-label="sales-footer">{languageData.termsandconditional}</a>
                      </li>
                  <li>
                    <a title={languageData.contact_2} href={`/contact/${language}`} aria-label="contact-footer">
                    {languageData.contact_2}
                      
                    </a>
                  </li>

                  <li>
                    
                  </li>
                 
                </ul>
              </div>
              <div></div>
              <div
                className="col-lg-5 col-md-5 offset-md-1 col-12 mb-5"
                style={{ cursor: "pointer" }}
              >
                <ImLocation2 />
                <p className="footer_text_main ">
                  <b> {languageData.headquarter}</b>
                  
                </p>
                <p className="footer_text">
                  Mustafa Kemal Mahallesi, Tepe Prime, C Blok, No: 71, 06530
                  Çankaya, Ankara, Türkiye
                  <br />{" "}
                 <li> <a
                    href="mailto:sales@focusactuators.com"
                    aria-label="mail-focus"
                  >
                    sales@focusactuators.com
                  </a></li>
           <li>  <a href="tel:+903122860906" aria-label="tel-focus">
                    +90 312 286 0906
                  </a></li>
                
               <li> <a href="tel:+908503097907" aria-label="tel-focus">
                    +90 850 309 7907
                  </a></li>
                 
                </p>

                <MdFactory />

                <p className="footer_text_main ">
                  <b>{languageData.factory}</b>
                </p>
                <p className="footer_text">
                  Ivedik OSB 1354. Cadde 1357. Sokak No:38 06378 Yenimahalle,
                  Ankara, Türkiye
                <li> <a
                    href="mailto:info@focusactuators.com"
                    aria-label="mail-focus"
                  >
                    info@focusactuators.com
                  </a></li>
                 
                </p>
                <BsFillTelephoneFill />
                <p className="footer_text">
                  <a href="tel:+908503097907" aria-label="tel-focus">
                    +90 850 309 7907
                  </a>
                </p>
                <SlEnvolopeLetter />
                <p classname="footer_text">
                  <a
                    href="mailto:sales@focusactuators.com"
                    aria-label="mail-focus"
                  >
                    sales@focusactuators.com
                  </a>
                </p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="focus-instagram"
                  href="https://www.instagram.com/focusactuators"
                  className="social"
                >
                  <AiOutlineInstagram />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="focus-linkedin"
                  href="https://www.linkedin.com/company/focusactuators/"
                  className="social"
                >
                  <AiOutlineLinkedin />
                </a>
                {/* <a
                  target="_blank"
                  href="https://www.linkedin.com/company/focusactuators/?originalSubdomain=tr"
                  className="social"
                  rel="noreferrer"
                  aria-label="focus-facebook"
                >
                  <AiOutlineFacebook />
                </a> */}
              </div>
              
            </div>
            <div className="col-12 text-white mb-3">{languageData.aboutus_text_5}</div>
          
          </div>
          <div className="row">
            <hr />
            <div className="col-12 d-flex p-0">
              <div className="col-6 d-flex justify-content-start">
                <p className="footer_text">
                  {" "}
                  {languageData.copyright_2023_focus_actuators_all_rights_reserved}
                 
                </p>
              </div>

              <div className="col-6 d-flex justify-content-end">
                {" "}
                <p className="footer_text">made by</p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label="osicrew"
                  href="https://osicrew.com/"
                >
                  <img className="osi-icon" src={osilogo} alt="osi logo"></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
