import React, { useState , useEffect} from 'react';
import products from '../data/products.json';
import "../assets/css/test.css";
import { useParams } from "react-router-dom";

const TestComponent = () => {
  const [selectedThumbnail, setSelectedThumbnail] = useState(products[0].img_slider[0]); // İlk ürünün ilk görüntüsünü varsayılan olarak seçin

  const handleThumbnailClick = (newSelection) => {
    setSelectedThumbnail(newSelection);
  };
  const {language:x} =useParams()
  useEffect(()=> {
    if (x && !["en","fr","ru","tr"].includes(x)) {
      window.location.pathname = `/404`;
    } else if (x && window.location.pathname.endsWith("/")) {
      const newPathname = window.location.pathname.slice(0, -1);
      window.location.pathname = newPathname;
    }
  }, [x]);
  
  return (
    <div className='gallery-products'>
      <div className="wrapper">
        <div className="image-gallery">
          <aside className="thumbnails">
            {products.map(product => (
              product.img_slider.map((image, index) => (
                <a href="#" key={index} className={selectedThumbnail === image ? "selected thumbnail" : "thumbnail"} onClick={() => handleThumbnailClick(image)}>
                  <div className="thumbnail-image" style={{backgroundImage: `url(${image})`}} />
                </a>
              ))
            ))}
          </aside>
          <main className="primary" style={{backgroundImage: `url(${selectedThumbnail})`}} />
        </div>
      </div>
    </div>
  );
}

export default TestComponent;
