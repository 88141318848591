import React, { useState, useEffect } from "react";
import "../assets/css/services.css";
import Fade from "react-reveal/Fade";
import Header from "../wrappers/Header";
import Footer from "../wrappers/Footer";
import Loader from "../wrappers/Loader";
import "../assets/css/about.css";
import img1 from "../assets/img/industries/water.png";
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import newsData from "../data/newsData.json";

const NewsDetailsComponent = () => {
  const languageData = useSelector(state => state.data);
  const currentLanguage = useSelector(state => state.language)
  const [showMenu, setShowMenu] = useState(false);
  const language = useSelector(state => state.language);
  const url = `https://www.focusactuators.com/newsdetails/:title/${language}`;

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const { title, language: x } = useParams();
  useEffect(() => {
    if (x && !["en", "fr", "ru", "tr"].includes(x)) {
      window.location.pathname = `/404`;
    } else if (x && window.location.pathname.endsWith("/")) {
      const newPathname = window.location.pathname.slice(0, -1);
      window.location.pathname = newPathname;
    }
  }, [x]);

  function removeTurkishChars(text) {
    const turkishChars = {
      ğ: "g",
      Ğ: "G",
      ü: "u",
      Ü: "U",
      ş: "s",
      Ş: "S",
      ı: "i",
      İ: "I",
      ö: "o",
      Ö: "O",
      ç: "c",
      Ç: "C",
    };

    return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]);
  }

  const newsdetail = newsData.news.find(
    (newsData) =>
      newsData &&
      removeTurkishChars(newsData.title_en).replace(/\s+/g, "-").toLowerCase() ===
      title
  );

  if (!newsdetail) {
    return <p>Haber Bulunamadı.</p>;
  }

  const images = [

    newsdetail.news_img_2,
    newsdetail.news_img_3,
    newsdetail.news_img_4,
    newsdetail.news_img_5,
  ].filter(src => src);

  return (
    <>
      <Helmet>
        <title>{languageData.news_menu}</title>
        <meta name="description" content="Water extraction and distribution, as well as wastewater management and cleansing, constitute fundamental necessities for the advancement of infrastructure." />
        <link
          rel="canonical"
          href={url}
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Loader />
      <Fade top delay={800}>
        <div className="downing-page-div">
          <Header showMenu={showMenu} toggleMenu={toggleMenu} />
          <div className="container-fluid text-header d-flex justify-content-center  flex-column align-items-center">
            {!showMenu && (
              <>
                <div className="col-12 title-sticky">
                  <h1 className="title-main-news"> {currentLanguage === 'en' ? newsdetail.title_en :
                    (currentLanguage === 'fr' ? newsdetail.title_fr :
                      (currentLanguage === 'ru' ? newsdetail.title_ru :
                        (currentLanguage === 'tr' ? newsdetail.title_tr : newsdetail.title)))}</h1>
                </div>
                <div className="mb-5 mt-2">
                  <div className="container d-flex justify-content-center align-items-center flex-column">
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="col-12 light-bg-gray">
                        <div className="text-white w-100">
                          <div className="col-lg-12 col-md-12 col-12 p-3 d-flex justify-content-between flex-column">
                            <div className="col-lg-12 col-md-12 col-12 p-3 d-flex align-items-center justify-content-center">
                              <img className="b_radius h-100" src={newsdetail.news_img} alt="focus actuators" width="700"
                                height="250"
                                style={{ borderRadius: '10px' ,width:'50%' , border:'5px solid black' }}></img>
                            </div>

                            <div key={newsdetail.blog_id}>
                              <div className="col-12 ">
                                <p className="title-services-inner-2"></p>
                              </div>
                              <div className="col-12 bg-gray">
                                <p className="p-0 m-0 text-white">
                                  {ReactHtmlParser(currentLanguage === 'en' ? newsdetail.desc_en :
                                    (currentLanguage === 'fr' ? newsdetail.desc_fr :
                                      (currentLanguage === 'ru' ? newsdetail.desc_ru :
                                        (currentLanguage === 'tr' ? newsdetail.desc_tr : newsdetail.desc_en))))}
                                  <br />
                                </p>
                              </div>
                              <div className="container mt-3">
                                <div className="row d-flex justify-content-center ">
                                  {images.map((src, index) => (
                                    <div key={index} className="col-6 d-flex justify-content-center mb-2 align-items-center">
                                      <img
                                        className="b_radius w-50 h-100"
                                        src={src}
                                        alt="focus actuators"
                    
                                        style={{borderRadius:'10px' , border:'3px solid black'}}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {!showMenu && <Footer />}
        </div>
      </Fade>
    </>
  );
};

export default NewsDetailsComponent;
