import React, { useState , useEffect} from "react";
import { useSelector } from 'react-redux'
import Footer from "../wrappers/Footer";
import Header from "../wrappers/Header";

const NotFoundComponent = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showFooter, setShowFooter] = useState(true);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    setShowFooter(showMenu);
  };
  
    const languageData = useSelector(state => state.data);
    return (
      <> 
                <Header  showMenu={showMenu} toggleMenu={toggleMenu} />

        <div className="d-flex justify-content-center align-items-center" style={{height:'60vh'}}>
            <div className="col-md-12 text-center">
            <h1>{languageData.page_not_found}</h1>
          <p>{languageData.not_found_title}</p>
          <a href="/" style={{fontSize:'30px' }}>{languageData.go_home}</a>
            </div>

        </div>
        {showFooter && <Footer />}
        </>
      );
  };

  


export default NotFoundComponent;
