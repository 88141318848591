import React from "react";
import "../assets/css/about.css";
import Menu from "../wrappers/Menu";
import Language from "./Language";
import { useSelector } from 'react-redux'

const Header= ({ showMenu, toggleMenu }) => {
  const languageData = useSelector(state => state.data);
  const language = useSelector(state => state.language);


  return (
    <>
         <div className="d-flex justify-content-end align-items-end flex-column ">
<div className=" position-absolute mx-lg-2 mx-md-2 m-0 language-top" ><Language /></div>

            <div className="container col-12 d-flex align-items-center">
              <div className="col-6 focus_text">

                  <a href={`/${language}`} aria-label="home-label" title={languageData.focus_actuators_1}>
                    <svg width="110" height="110" viewBox="0 0 175 175">
                      <defs>
                        <clipPath id="clip-path">
                          <rect
                            id="Rectangle_93"
                            data-name="Rectangle 93"
                            width="175"
                            height="175"
                            transform="translate(989 4311)"
                            fill="none"
                          />
                        </clipPath>
                      </defs>
                      <g
                        id="Mask_Group_5"
                        data-name="Mask Group 5"
                        transform="translate(-989 -4311)"
                        clip-path="url(#clip-path)"
                      >
                        <g
                          id="focus_logo-01"
                          transform="translate(809 3991.449)"
                        >
                          <path
                           style={{ fill: showMenu ? "white" : ""}}
                            id="Path_14"
                            data-name="Path 14"
                            d="M293.514,458.195a11.983,11.983,0,0,1-13.727,0l-5.387,5.387a19.566,19.566,0,0,0,24.527,0Zm8.367-22.094-5.387,5.387a11.983,11.983,0,0,1,0,13.727l5.387,5.387a19.527,19.527,0,0,0,0-24.5Zm-15.23-7.3a19.367,19.367,0,0,0-12.25,4.32l5.387,5.387a11.983,11.983,0,0,1,13.727,0l5.387-5.387A19.368,19.368,0,0,0,286.651,428.8Zm-15.23,7.3a19.566,19.566,0,0,0,0,24.527l5.387-5.387a11.983,11.983,0,0,1,0-13.727Z"
                            transform="translate(-63.284 -35.056)"
                          />
                          <path
                            id="Path_15"
                            style={{ fill: showMenu ? "white" : ""}}
                            data-name="Path 15"
                            d="M430.3,448.915a19.705,19.705,0,0,1,36.75-9.9,3.941,3.941,0,0,1-1.422,5.332,4.018,4.018,0,0,1-5.332-1.422,11.95,11.95,0,1,0-.3,12.414,4.006,4.006,0,0,1,5.414-1.176,3.91,3.91,0,0,1,1.094,5.414,19.666,19.666,0,0,1-36.2-10.664Z"
                            transform="translate(-181.859 -35.346)"
                            fill="#1d1d1b"
                          />
                          <path
                            id="Path_16"
                            style={{ fill: showMenu ? "white" : ""}}
                            data-name="Path 16"
                            d="M616,433.055v19.8a15.9,15.9,0,0,1-31.8,0v-19.8a3.9,3.9,0,0,1,7.793,0v19.633a8.135,8.135,0,1,0,16.27,0V433.055a3.869,3.869,0,0,1,7.738,0Z"
                            transform="translate(-293.677 -35.346)"
                            fill="#1d1d1b"
                          />
                          <path
                           style={{ fill: showMenu ? "white" : ""}}
                            id="Path_17"
                            data-name="Path 17"
                            d="M747.271,456.118c0,2.27-1.641,11.785-13.125,11.785-5.5,0-11.922-2.434-14.055-7.93a2.424,2.424,0,0,1-.164-1.094,4.046,4.046,0,0,1,3.992-3.855,3.809,3.809,0,0,1,2.98,1.5c1.8,2.516,3.691,3.527,7.055,3.691,5.031,0,5.414-3.445,5.5-4.074,0-2.68-2.734-3.773-6.508-4.156-4.785-.547-13.043-3.145-13.043-11.785,0-7.547,6.371-12,13.262-12a16.94,16.94,0,0,1,7.547,1.8c.793.383,6.508,2.98,6.508,7.766a4.157,4.157,0,0,1-3.992,3.773c-1.887,0-2.68-1.258-3.609-2.68-1.094-2.187-4.4-2.9-6.59-2.9-2.9,0-5.414,1.641-5.414,4.32,0,5.66,8.477,1.969,15.012,7A11.707,11.707,0,0,1,747.271,456.118Z"
                            transform="translate(-392.271 -34.62)"
                            fill="#1d1d1b"
                          />
                          <path
                            id="Path_18"
                            style={{ fill: showMenu ? "white" : ""}}
                            data-name="Path 18"
                            d="M227.868,382.889a3.934,3.934,0,0,0-4.949-2.105,19.157,19.157,0,0,0-7.383,4.156,16.758,16.758,0,0,0-5.742,12.934v31.8a3.687,3.687,0,0,0,3.855,3.527,3.728,3.728,0,0,0,3.938-3.527v-31.8a10.3,10.3,0,0,1,3.445-8.012,11.5,11.5,0,0,1,4.484-2.543A3.38,3.38,0,0,0,227.868,382.889Z"
                            transform="translate(-21.645 0)"
                            fill="#1d1d1b"
                          />
                          <path
                            id="Path_19"
                            data-name="Path 19"
                            d="M222.355,493.666H183.883A3.88,3.88,0,0,1,180,489.783h0a3.88,3.88,0,0,1,3.883-3.883h38.473a3.88,3.88,0,0,1,3.883,3.883h0A3.863,3.863,0,0,1,222.355,493.666Z"
                            transform="translate(0 -76.542)"
                            fill="#9d2e29"
                          />
                        </g>
                      </g>
                    </svg>
                  </a>
               
              </div>
              <div className="col-6 d-flex justify-content-end">
                <Menu show={showMenu} toggleMenu={toggleMenu} />
              </div>
            </div>
          </div>
    </>
  );
};

export default Header;
