import React, { useState , useEffect } from "react";
import "../assets/css/services.css";
import Fade from "react-reveal/Fade";
import Header from "../wrappers/Header";
import Footer from "../wrappers/Footer";
import Loader from "../wrappers/Loader";
import "../assets/css/about.css";
import img1 from "../assets/img/industries/water.png";
import img2 from "../assets/img/industries/energy.png";
import img3 from "../assets/img/industries/industry.png";
import {Helmet} from "react-helmet";
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom";

const Industries = () => {
  const languageData = useSelector(state => state.data);
  const [showMenu, setShowMenu] = useState(false);
  const language = useSelector(state => state.language);
  const url = `https://www.focusactuators.com/industries/${language}`;

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const {language:x} =useParams()
  useEffect(()=> {
    if (x && !["en","fr","ru","tr"].includes(x)) {
      window.location.pathname = `/404`;
    } else if (x && window.location.pathname.endsWith("/")) {
      const newPathname = window.location.pathname.slice(0, -1);
      window.location.pathname = newPathname;
    }
  }, [x]);

  return (
    <>
    <Helmet>
        <title>{languageData.industries_helmet}</title>
        <meta name="description" content="Water extraction and distribution, as well as wastewater management and cleansing, constitute fundamental necessities for the advancement of infrastructure."/>
        <link
          rel="canonical"
          href={url}
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Loader />
      <Fade top delay={800}>
        <div className="downing-page-div">
          <Header showMenu={showMenu} toggleMenu={toggleMenu} />
          <div className="container-fluid text-header d-flex justify-content-center  flex-column align-items-center">
            {!showMenu && (
              <>
                <div className="col-12 title-sticky">
                  <h1 className="title-main">{languageData.INDUSTRIES}</h1>
                </div>

                <div className="mb-5 mt-2">
                  <div className="container d-flex justify-content-center align-items-center flex-column">
                    <div className="col-lg-12 col-md-12 col-12  ">
                      <div className="col-12 light-bg-gray">
                        <div className=" d-lg-flex d-md-flex text-white w-100">
                          <div className="col-lg-7 col-md-7 col-12 p-3 d-flex justify-content-between flex-column">
                            <div className="col-12 mt-5">
                              <p className="title-services-inner-2">{languageData.WATER}</p>
                            </div>
                            <div className="col-12 bg-gray ">
                              <p className="p-0 m-0 text-white">
                              {languageData.industries_text_1}
                                <br />
                                <ul className="ms-4">
                                  <li>
                                  {languageData.facilities_for_wastewater_purification}
                                  </li>
                                  <li>{languageData.facilities_for_treating_water}</li>
                                  <li>{languageData.distributing_potable_water}</li>
                                  <li>{languageData.managing_sewage_disposal}</li>
                                  <li>{languageData.desalination_of_seawater}</li>
                                  <li>{languageData.steel_structures_for_water_systems}</li>
                                </ul>
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-5 col-md-5 col-12 p-3 d-flex align-items-center justify-content-center">
                            <img className="b_radius h-100" src={img1} alt="focus actuators"></img>
                          </div>
                        </div>
                        <div className="col-12 d-lg-flex d-md-flex">
                          <div className="p-3 d-flex justify-content-center align-items-center col-lg-4 col-md-4 col-12">
                            <img className="b_radius h-100" src={img2} alt="focus actuators"></img>
                          </div>
                          <div className="col-lg-8 col-md-8 col-12 p-3">
                            <div className="col-12 d-flex justify-content-end">
                              <p className="title-services-inner-2">{languageData.ENERGY}</p>
                            </div>
                            <div className="bg-gray text-white m-2">
                              <p className="p-0 m-0 text-white">
                              {languageData.industries_text_2}
                              </p>
                              <br />
                              <ul className="ms-4">
                                <li>
                                {languageData.conventional_power_plants_coal_gas_oil}
                                </li>
                                <li> {languageData.nuclear_power_facilities}</li>
                                <li>{languageData.combined_heat_and_power_plants}</li>
                                <li>{languageData.urban_heating_networks}</li>
                                <li> {languageData.hydroelectric_energy_stations}</li>
                                <li> {languageData.geothermal_energy_facilities}</li>
                                <li>{languageData.concentrated_solar_power_plants}</li>
                                <li>{languageData.biogas_energy_facilities}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 d-lg-flex d-md-flex">
                          <div className="col-lg-8 col-md-8 col-12 p-3 d-flex flex-column justify-content-between">
                            <div className="col-12 mt-5">
                              <p className="title-services-inner-2">{languageData.INDUSTRY}</p>
                            </div>
                            <div className="bg-gray text-white m-2">
                              <p className="p-0 m-0 text-white">
                              {languageData.industries_text_3}
                              </p>
                              <br />
                              <ul className="ms-4">
                                <li>
                                {languageData.hvac_heating_ventilation_air_conditioning}
                                </li>
                                <li>{languageData.food_processing_sector}</li>
                                <li>
                                {languageData.chemical_and_pharmaceutical_manufacturing}
                                </li>
                                <li>{languageData.construction_of_vessels_and_submarines}</li>
                                <li>{languageData.steel_production_facilities}</li>
                                <li>{languageData.paper_manufacturing_sector}r</li>
                                <li>{languageData.cement_production_industry}</li>
                                <li>{languageData.mining_operations}</li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-12 p-3 d-flex justify-content-center">
                            <img className="b_radius h-100" src={img3}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {!showMenu && <Footer />}
        </div>
      </Fade>
    </>
  );
};

export default Industries;
