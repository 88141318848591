import React, { useState , useEffect} from "react";
import "../assets/css/services.css";
import Fade from "react-reveal/Fade";
import Header from "../wrappers/Header";
import Footer from "../wrappers/Footer";
import Loader from "../wrappers/Loader";
import "../assets/css/about.css";
import img1 from "../assets/img/services/sevices_support_1.png";
import img2 from "../assets/img/services/services_support_3.png";
import img3 from "../assets/img/services/services_support_2.png";
import {Helmet} from "react-helmet";
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom";

const ServiceComponent = () => {
  const languageData = useSelector(state => state.data);
  const [showMenu, setShowMenu] = useState(false);
  const language = useSelector(state => state.language);
  const url = `https://www.focusactuators.com/service_support/${language}`;

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const {language:x} =useParams()
  useEffect(()=> {
    if (x && !["en","fr","ru","tr"].includes(x)) {
      window.location.pathname = `/404`;
    } else if (x && window.location.pathname.endsWith("/")) {
      const newPathname = window.location.pathname.slice(0, -1);
      window.location.pathname = newPathname;
    }
  }, [x]);

  return (
    <>
    <Helmet>
        <title>{languageData.services_support_helmet}</title>
        <meta name="description" content="At Focus Actuators, our dedicated technical support and sales units are committed to assisting you at the pre-order stage, ensuring seamless compatibility between your valves and actuators"/>
        <link
          rel="canonical"
          href={url}
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Loader />
      <Fade top delay={800}>
        <div className="downing-page-div">
          <Header showMenu={showMenu} toggleMenu={toggleMenu} />
          <div className="container-fluid text-header d-flex justify-content-center  flex-column align-items-center">
            {!showMenu && (
              <>
                <div className="col-12 title-sticky">
                  <h1 className="title-main-services">{languageData.services_support}</h1>
                </div>
                <div>
                  <div className="container d-flex justify-content-center align-items-center flex-column">
                    <div className="col-lg-12 col-12 mb-5 ">
                      <div className="col-12 light-bg-gray">
                        <div className=" d-lg-flex d-md-flex text-white w-100">
                          <div className="col-lg-8 col-md-7 col-12 p-3">
                            <div className="col-12 h-100 bg-gray d-flex align-items-center">
                              <p className="p-0 m-0 text-white p-2 p-lg-2">
                              {languageData.services_text_1}
                                <li>
                                {languageData.services_subtext_1}
                                </li>
                                <li> {languageData.services_subtext_2}</li>
                                <li>
                                {languageData.services_subtext_3}
                                </li>
                                <li> {languageData.services_subtext_4}</li>
                                <li>
                                {languageData.services_subtext_5}
                                </li>
                                <li>
                                {languageData.services_subtext_6}
                                </li>
                                <li>
                                {languageData.services_subtext_7}
                                </li>
                                {languageData.services_text_2}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-5 col-12 d-flex align-items-center justify-content-center p-3">
                            <img
                              className="b_radius h-100"
                              src={img1}
                              alt="focus actuators"
                            ></img>
                          </div>
                        </div>
                        <div className="col-12 d-lg-flex d-md-flex">
                          <div className="col-lg-4 col-md-4 col-12 p-3  d-flex align-items-center justify-content-center ">
                            <img
                              className="b_radius h-100"
                              src={img2}
                              alt="focus actuators"
                            ></img>
                          </div>
                          <div className="col-lg-8 col-md-8 col-12 p-3">
                            <div className="bg-gray h-100 text-white d-flex align-items-center  position-relative">
                              <p className="p-0 m-0 text-white p-2 p-lg-2">
                              {languageData.services_text_3}
                                <br />
                                <br />
                                {languageData.services_text_4}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 d-lg-flex d-md-flex">
                          <div className="col-lg-8 col-md-8 col-12 p-3">
                            <div className="bg-gray d-flex align-items-center  text-white ">
                              <p className="p-0 m-0 text-white p-2 p-lg-2">
                              {languageData.services_text_5}
                                <br />
                                <br />
                                {languageData.services_text_6}
                                <br />
                                <br />
                                {languageData.services_text_7}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-12 p-3 d-flex align-items-center justify-content-center">
                            <img
                              className="b_radius h-100"
                              src={img3}
                              alt="focus actuators"
                            ></img>
                          </div>
                        </div>

                        <div className="col-12 d-flex  p-3">
                          <div className="bg-gray h-100">
                            <p className="text-white p-2 p-lg-2">
                            {languageData.services_text_8}
                              <br />
                              <br />
                              {languageData.services_text_9}
                              <br />
                              <br />
                              {languageData.services_text_10}
                              <br />
                              <a
                                className="mail_support"
                                href="mailto:support@focusactuators.com"
                              >
                                support@focusactuators.com
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {!showMenu && <Footer />}
        </div>
      </Fade>
    </>
  );
};

export default ServiceComponent;
