import React, { useState, useEffect } from "react";
import "../assets/css/products.css";
import Header from "../wrappers/Header";
import Fade from "react-reveal/Fade";
import Footer from "../wrappers/Footer";
import Loader from "../wrappers/Loader";
import "../assets/css/about.css";
import documentsData from "../data/documents.json";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "../assets/css/documents.css";
import general from "../assets/img/general.png";
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom";

const DocumentsComponent = () => {
  const languageData = useSelector(state => state.data);
  const [showMenu, setShowMenu] = useState(false);
  const currentLanguage = useSelector(state => state.language)
  const language = useSelector(state => state.language);
  const url = `https://www.focusactuators.com/documents/${language}`;

  const { language: x } = useParams()
  useEffect(() => {
    if (x && !["en", "fr", "ru", "tr"].includes(x)) {
      window.location.pathname = `/404`;
    } else if (x && window.location.pathname.endsWith("/")) {
      const newPathname = window.location.pathname.slice(0, -1);
      window.location.pathname = newPathname;
    }
  }, [x]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      <Helmet>
        <title>{languageData.documents}</title>
        <meta name="description" content="You may find all focus documentation in this section including product catalogues and other." />
        <link
          rel="canonical"
          href={url}
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Loader />
      <Fade top delay={800}>
        <div className="downing-page-div documents-main">
          <Header showMenu={showMenu} toggleMenu={toggleMenu} />
          <div className="container text-header d-flex justify-content-center flex-column">
            {!showMenu && (
              <>
                <div className="title-sticky">
                  <h1 className="title-main">{languageData.documents_1}</h1>
                </div>
                <div className="products-page-inner">  <div className="row mb-5 ">
                  <p className="col-lg-6 col-12 text-black d-flex align-items-center">
                    {languageData.doc_text_1}
                  </p>

                  <div className="col-lg-6 col-12 d-flex justify-content-end">
                    <img src={general} alt="focus actuators" />
                  </div>
                </div>


                  <div className="col-lg-9 col-12 text-white mt-2 mb-5 w-100">
                    <Tabs>
                      <TabList>
                        <Tab>{languageData.products}</Tab>
                        <Tab>{languageData.general}</Tab>
                        <Tab>{languageData.certificates}</Tab>
                      </TabList>

                      <TabPanel>
                        <div className="col-12">
                          <div className="d-flex flex-wrap">
                            {documentsData.documents
                              .filter((document) => document.id === "product")
                              .map((document) => (
                                <div
                                  key={document.doc_no}
                                  className="col-lg-4 col-12 mb-3 "
                                >
                                  <div className="bg-gray text-white m-2 h-100">
                                    <div className="w-100 documents-img mb-4 bg-black d-flex align-items-center justify-content-center b_radius ">
                                      {document.img !== "" ? (
                                        <img
                                          src={document.img}
                                          alt="Document"
                                        />
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                    <div className="d-flex w-100 justify-content-between ">
                                      <div>
                                        {" "}
                                        <h4>{currentLanguage === 'en' ? document.title :
                                          (currentLanguage === 'fr' ? document.title_fr :
                                            (currentLanguage === 'ru' ? document.title_ru :
                                              (currentLanguage === 'tr' ? document.title_tr : document.title)))}</h4>
                                        <p className="m-0">{currentLanguage === 'en' ? document.desc :
                                          (currentLanguage === 'fr' ? document.desc_fr :
                                            (currentLanguage === 'ru' ? document.desc_ru :
                                              (currentLanguage === 'tr' ? document.desc_tr : document.desc)))}</p>
                                      </div>

                                      <a
                                        rel="noreferrer"
                                        aria-label="document"
                                        className="doc_btn"
                                        target="_blank"
                                        href={
                                          document && document.href 
                                            ? (language === 'tr' ? `/pdf_tr/${document.href}` 
                                                : language === 'ru' ? `/pdf_ru/${document.href}` 
                                                    : language === 'fr' ? `/pdf_fr/${document.href}` 
                                                        : `/pdf_en/${document.href}`) 
                                            : `/pdf_en/${document.href}`
                                        }
                                        
                                        
                                      >

                                        <p
                                          data-hover="download"
                                          className="doc_btn_content button-focus m-0"
                                        >
                                          {languageData.download_1}
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel>
                        <div className="col-12">
                          <div className="d-flex flex-wrap">
                            {documentsData.documents
                              .filter((document) => document.id === "general")
                              .map((document) => (
                                <div
                                  key={document.doc_no}
                                  className="col-lg-4 col-12 mb-3 "
                                >
                                  <div className=" bg-gray text-white m-2 h-100">
                                    <div className="w-100 documents-img mb-4 bg-black d-flex align-items-center justify-content-center b_radius ">
                                      {document.img !== "" ? (
                                        <img
                                          src={document.img}
                                          alt="Document"
                                        />
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                    <div className="d-flex w-100 justify-content-between">
                                      <div>
                                        {" "}
                                        <h4>{currentLanguage === 'en' ? document.title :
                                          (currentLanguage === 'fr' ? document.title_fr :
                                            (currentLanguage === 'ru' ? document.title_ru :
                                              (currentLanguage === 'tr' ? document.title_tr : document.title)))}</h4>

                                      </div>

                                      <a
                                        rel="noreferrer"
                                        aria-label="document-2"
                                        className="doc_btn"
                                        target="_blank"
                                        href={document.href.endsWith('.pdf') ?
                                          (language === 'tr' ? `/pdf_tr/${document.href}` :
                                            language === 'ru' ? `/pdf_ru/${document.href}` :
                                              language === 'fr' ? `/pdf_fr/${document.href}` :
                                                `/pdf_en/${document.href}`) :
                                          `/pdf_${language}/${document.href}`}

                                      >
                                        <p
                                          data-hover="download"
                                          className="doc_btn_content button-focus m-0"
                                        >
                                          {languageData.download_1}
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel>
                        <div className="col-12">
                          <div className="d-flex flex-wrap">
                            {documentsData.documents
                              .filter((document) => document.id === "certificates")
                              .map((document) => (
                                <div
                                  key={document.doc_no}
                                  className="col-lg-4 col-12 mb-3 "
                                >
                                  <div className=" bg-gray text-white m-2 h-100">
                                    <div className="w-100 documents-img mb-4 bg-black d-flex align-items-center justify-content-center b_radius ">
                                      {document.img !== "" ? (
                                        <img
                                          src={document.img}
                                          alt="Document"
                                        />
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                    <div className="d-flex w-100 justify-content-between">
                                      <div>
                                        {" "}
                                        <h4>{currentLanguage === 'en' ? document.title :
                                          (currentLanguage === 'fr' ? document.title_fr :
                                            (currentLanguage === 'ru' ? document.title_ru :
                                              (currentLanguage === 'tr' ? document.title_tr : document.title)))}</h4>

                                      </div>

                                      <a
                                        rel="noreferrer"
                                        aria-label="document-2"
                                        className="doc_btn"
                                        target="_blank"
                                        href={document.href.endsWith('.pdf') ?
                                          (language === 'tr' || language === 'ru' || language === 'fr' ? `/pdf_en/${document.href}` : `/pdf_${language}/${document.href}`)
                                          :
                                          `/pdf_${language}/${document.href}`}
                                      >
                                        <p
                                          data-hover="download"
                                          className="doc_btn_content button-focus m-0"
                                        >
                                          {languageData.download_1}
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>

              </>
            )}
          </div>
          {!showMenu && <Footer />}
        </div>
      </Fade>
    </>
  );
};

export default DocumentsComponent;
