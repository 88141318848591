import React, { useState, useEffect } from "react";
import "../assets/css/products.css";
import Header from "../wrappers/Header";
import Fade from "react-reveal/Fade";
import Footer from "../wrappers/Footer";
import Loader from "../wrappers/Loader";
import "../assets/css/about.css";
import blogData from "../data/blog.json";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "../assets/css/documents.css";
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const BlogComponent = () => {
    const languageData = useSelector(state => state.data);
    const [showMenu, setShowMenu] = useState(false);
    const currentLanguage = useSelector(state => state.language)
    const language = useSelector(state => state.language);
    const url = `https://www.focusactuators.com/blog/${language}`;

    const { language: x } = useParams()
    useEffect(() => {
        if (x && !["en", "fr", "ru", "tr"].includes(x)) {
            window.location.pathname = `/404`;
        } else if (x && window.location.pathname.endsWith("/")) {
            const newPathname = window.location.pathname.slice(0, -1);
            window.location.pathname = newPathname;
        }
    }, [x]);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    function removeTurkishChars(text) {
        const turkishChars = {
            ğ: "g",
            Ğ: "G",
            ü: "u",
            Ü: "U",
            ş: "s",
            Ş: "S",
            ı: "i",
            İ: "I",
            ö: "o",
            Ö: "O",
            ç: "c",
            Ç: "C",
        };


        return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]);
    }


    return (
        <>
            <Helmet>
                <title>{languageData.blog_menu}</title>
                <meta name="description" content="You may find all focus documentation in this section including product catalogues and other." />
                <link
                    rel="canonical"
                    href={url}
                />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <Loader />
            <Fade top delay={800}>
                <div className="downing-page-div documents-main">
                    <Header showMenu={showMenu} toggleMenu={toggleMenu} />
                    <div className="container text-header d-flex justify-content-center flex-column">
                        {!showMenu && (
                            <>
                                <div className="title-sticky">
                                    <h1 className="title-main">{languageData.blog_upper}</h1>
                                </div>
                                <div className="products-page-inner">  <div className="row mb-5 ">
                                </div>
                                    <div className="col-lg-9 col-12 text-white mt-2 mb-5 w-100">
                                        <Tabs>

                                            <TabPanel>
                                                <div className="col-12">
                                                    <div className="d-flex flex-wrap">
                                                        {blogData.blogs
                                                            .map((blog) => (
                                                                <div
                                                                    key={blog.blog_id}
                                                                    className="col-lg-4 col-12 mb-3 "
                                                                >
                                                                    <div className="bg-gray text-white m-2 h-100 d-flex flex-column justify-content-between">
                                                                        <div className= "h-100 w-100 documents-img mb-4 bg-black  d-flex align-items-center justify-content-center b_radius ">
                                                                            {blog.blog_img !== "" ? (
                                                                                <img
                                                                                    src={blog.blog_img}
                                                                                    alt="Blog"
                                                                                    width="600"
                                                                                    height="250"
                                                                                    style={{borderRadius:'10px',objectFit:'contain'}}
                                                                                />
                                                                            ) : (
                                                                                <p></p>
                                                                            )}
                                                                        </div>
                                                                        <div className=" w-100 justify-content-between ">
                                                                            <div>
                                                                                {" "}
                                                                                <h4>{currentLanguage === 'en' ? blog.blog_title :
                                                                                    (currentLanguage === 'fr' ? blog.blog_title :
                                                                                        (currentLanguage === 'ru' ? blog.blog_title :
                                                                                            (currentLanguage === 'tr' ? blog.blog_title : blog.blog_title)))}</h4>

                                                                            </div>
                                                                                <div>
                                                                            <Link
                                                                                rel="noreferrer"
                                                                                aria-label="document"
                                                                                className="doc_btn"
                                                                                to={`/blogdetails/${removeTurkishChars(blog.blog_title).replace(/\s+/g, "-").toLowerCase()}/${language}`}
                                                                            >
                                                                                <p data-hover="download" className="doc_btn_content button-focus m-0">
                                                                                    {languageData.read_more}
                                                                                </p>
                                                                            </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            </TabPanel>


                                        </Tabs>
                                    </div>
                                </div>

                            </>
                        )}
                    </div>
                    {!showMenu && <Footer />}
                </div>
            </Fade>
        </>
    );
};

export default BlogComponent;
