import React, { useState, useEffect } from 'react';
import products from '../data/products.json';
import "../assets/css/test.css";
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Footer from "../wrappers/Footer";
import Header from "../wrappers/Header";
import Loader from "../wrappers/Loader";
import { useSelector } from 'react-redux'
import { Fade } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import "../assets/css/about.css";
import termsJson from "../data/termsAndConditions.json"


const TermsconditionsComponent = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [showFooter, setShowFooter] = useState(true);
    const toggleMenu = () => {
        setShowMenu(!showMenu);
        setShowFooter(showMenu);
    };
    const languageData = useSelector(state => state.data);
    const language = useSelector(state => state.language);
    const url = `https://www.focusactuators.com/salesterms/${language}`;


    const { language: x } = useParams()
    useEffect(() => {
        if (x && !["en", "fr", "ru", "tr"].includes(x)) {
            window.location.pathname = `/404`;
        } else if (x && window.location.pathname.endsWith("/")) {
            const newPathname = window.location.pathname.slice(0, -1);
            window.location.pathname = newPathname;
        }
    }, [x]);

 
    const termsAndConditional = termsJson.termsAndConditions;

    return (
        <>
            <Helmet>
                <title>{languageData.termsandconditional}</title>
                <meta name="description" content="FOCUS Terms & Conditions: Detailed legal terms governing your use of our services and products. Read before proceeding." />
                <link
                    rel="canonical"
                    href={url}
                />
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <Loader />
             <Fade top delay={800}>
        <div className="downing-page-div">
          <Header showMenu={showMenu} toggleMenu={toggleMenu} />
          <div className="container-fluid text-header d-flex justify-content-center flex-column align-items-center">
            {!showMenu && (
              <>
                <div className="col-12 title-sticky">
                  <h1 className="title-main">FOCUS Terms & Conditions</h1>
                </div>

                <div className="mb-5 mt-2">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 light-bg-gray p-3">
                        <div className="bg-gray p-4">
                          <div className="text-white">
                            {ReactHtmlParser(termsAndConditional)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {!showMenu && <Footer />}
        </div>
      </Fade>
        </>
    );
}

export default TermsconditionsComponent;
