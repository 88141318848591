// App.js

import React, { useState, useEffect } from "react";
import "./App.css";
import HomeTwoComponent from "./components/HomeTwoComponent";
import { BrowserRouter, Routes, Route, Navigate, useParams } from "react-router-dom";
import AboutComponent from "./components/AboutComponent";
import ProductsComponent from "./components/ProductsComponent";
import ContactComponent from "./components/ContactComponent";
import BecomeDistributor from "./components/BecomeDistributor";
import ServiceComponent from "./components/ServiceComponent";
import ScrollToTop from "./components/ScrollToTop";
import WhyFocus from "./components/WhyFocus";
import Industries from "./components/Industries";
import Faq from "./components/Faq";
import DocumentsComponent from "./components/DocumentsComponent";
import HRComponent from "./components/HRComponent";
import rotate from "./assets/img/rotate.png";
import TestComponent from "./components/TesComponent";
import { Provider } from 'react-redux';
import store from "./redux/reducers/store"
import NotFoundComponent from "./components/NotFoundComponent";
import { useSelector } from "react-redux";
import ProductDetailComponent from "./components/ProductDetailComponent";
import MultiTurnComponent from "./components/MultiTurnComponent";
import QuarterTurnComponent from "./components/QuarterTurnComponent";
import GearboxesComponent from "./components/GearboxesComponent";
import TermsconditionsComponent from "./components/TermsconditionsComponent";
import NewsComponent from "./components/NewsComponent";
import BlogComponent from "./components/BlogComponent";
import BlogDetailsComponent from "./components/BlogDetailsComponent";
import NewsDetailsComponent from "./components/NewsDetailComponents";
function App() {


  const language = useSelector(state => state.language);



  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
         

          <Routes>
           <Route path="/" element={<Navigate to={`/${language}`} />} /> 

            <Route path="/:language" element={<HomeTwoComponent />} />
            <Route path="/about_focus/:language" element={<AboutComponent />} />
            <Route path="/why_focus/:language" element={<WhyFocus />} />
             {/* <Route path="/products/:language" element={<ProductsComponent />} />  */}
             <Route path="/:title/:language" element={<ProductsComponent  />} />
            <Route path="/products/:language" element={<ProductDetailComponent />} />
            <Route path="/contact/:language" element={<ContactComponent />} />
            <Route path="/become_a_distributor/:language" element={<BecomeDistributor />} />
            <Route path="/service_support/:language" element={<ServiceComponent />} />
            <Route path="/industries/:language" element={<Industries />} />
            <Route path="/faq/:language" element={<Faq />} />
            <Route path="/salesterms/:language" element={<TermsconditionsComponent />} />
            <Route path="/test/:language" element={<TestComponent />} />
            <Route path="/documents/:language" element={<DocumentsComponent />} />
            <Route path="/human_resources/:language" element={<HRComponent />} />
            <Route path="/404" element={<NotFoundComponent />} />
            {/* <Route exact path="*" element={<NotFoundComponent />} /> */}
            <Route path="/multi-turn_electric_actuators/:language" element={<MultiTurnComponent />} />
            <Route path="/quarter-turn_electric_actuators/:language" element={<QuarterTurnComponent />} />
            <Route path="/bevel-gearboxes/:language" element={<GearboxesComponent />} />
           
            <Route path="/blog/:language" element={<BlogComponent />} />
            <Route path="/blogdetails/:title/:language" element={<BlogDetailsComponent />} />
            <Route path="/news/:language" element={<NewsComponent />} />
            <Route path="/newsdetails/:title/:language" element={<NewsDetailsComponent />} />





          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
