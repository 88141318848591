import React, { useState, useEffect } from "react";

const MainLoader = () => {
  const [blur, setBlur] = useState("blur(8px)");
  const [opacity, setOpacity] = useState("0");
  const [opacityTwo, setOpacityTwo] = useState("0");

  useEffect(() => {
    const timer = setTimeout(() => {
      setBlur("blur(0px)");
    }, 800);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity("1");
    }, 400);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacityTwo("1");
    }, 100);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div
      className="d-flex justify-content-center align-items-center main-loader"
      style={{ height: "100vh" }}
    >
      <svg
        version="1.1"
        id="Layer_1"
        viewBox="0 0 1000 1000"
        style={{ enableBackground: "new 0 0 1000 1000" }}
        xmlSpace="preserve"
      >
        <g>
          <path
            style={{
              filter: blur,
              opacity: opacity,
              transition: "filter 2s ease-in-out, opacity 1s ease-in-out",
            }}
            d="M363.7,536.3c-7.1,5-15.8,7.9-25.1,7.9c-9.3,0-18-2.9-25.1-7.9l-19.7,19.7c12.3,9.9,27.8,15.8,44.8,15.8
		c17,0,32.6-5.9,44.9-15.8L363.7,536.3z M394.3,455.5l-19.7,19.7c5,7.1,7.9,15.8,7.9,25.1c0,9.3-2.9,18-7.9,25.1l19.7,19.7
		c9.9-12.3,15.8-27.9,15.8-44.8C410.1,483.4,404.2,467.8,394.3,455.5z M338.6,428.8c-17,0-32.6,5.9-44.8,15.8l19.7,19.7
		c7.1-5,15.8-7.9,25.1-7.9c9.3,0,18,2.9,25.1,7.9l19.7-19.7C371.2,434.7,355.6,428.8,338.6,428.8z M282.9,455.5
		c-9.9,12.3-15.8,27.9-15.8,44.9c0,17,5.9,32.6,15.8,44.8l19.7-19.7c-5-7.1-7.9-15.8-7.9-25.1c0-9.4,2.9-18,7.9-25.1L282.9,455.5z"
          />
          <path
            style={{
              opacity: opacityTwo,
              transition: "opacity 1s ease-in-out",
            }}
            className="st0"
            d="M430.3,501.3c0-39.9,32.2-72.1,71.8-72.1c25.8,0,49.7,13.8,62.6,35.9c4,6.9,1.4,15.5-5.2,19.5
		c-6.9,3.7-15.5,1.4-19.5-5.2c-7.8-13.5-22.1-21.8-37.9-21.8c-23.8,0-43.7,19.5-43.7,43.7c0,23.8,19.8,43.6,43.7,43.6
		c14.9,0,28.7-7.5,36.8-20.1c4.3-6.6,13.2-8.3,19.8-4.3c6.6,4.3,8.3,13.2,4,19.8c-13.2,20.7-35.9,33-60.6,33
		C462.5,573.4,430.3,540.9,430.3,501.3z"
          />
          <path
            style={{
              filter: blur,
              opacity: opacity,
              transition: "filter 2s ease-in-out, opacity 1s ease-in-out",
            }}
            className="st0"
            d="M700.5,443.3v72.1v0.3c-0.3,31.9-26.1,57.7-58.3,57.7c-31.9,0-57.7-25.8-58-57.7v-0.3v-72.1
		c0-7.8,6.3-14.1,14.1-14.1c8,0,14.4,6.3,14.4,14.1v71.8c0,16.4,13.2,29.9,29.6,29.9c16.7,0,29.9-13.5,29.9-29.9v-71.8
		c0-7.8,6.3-14.1,14.4-14.1C694.2,429.2,700.5,435.5,700.5,443.3z"
          />
          <path
            style={{
              filter: blur,
              opacity: opacity,
              transition: "filter 2s ease-in-out, opacity 1s ease-in-out",
            }}
            className="st0"
            d="M820,530.3c0,8.3-6,43.1-48,43.1c-20.1,0-43.6-8.9-51.4-29c-0.6-1.2-0.6-2.6-0.6-4c0-7.2,6.6-14.1,14.6-14.1
		c4,0,8.3,2,10.9,5.5c6.6,9.2,13.5,12.9,25.8,13.5c18.4,0,19.8-12.6,20.1-14.9c0-9.8-10-13.8-23.8-15.2c-17.5-2-47.7-11.5-47.7-43.1
		c0-27.6,23.3-43.9,48.5-43.9c9.5,0,19,2.3,27.6,6.6c2.9,1.4,23.8,10.9,23.8,28.4c-0.6,7.2-6.9,13.8-14.6,13.8
		c-6.9,0-9.8-4.6-13.2-9.8c-4-8-16.1-10.6-24.1-10.6c-10.6,0-19.8,6-19.8,15.8c0,20.7,31,7.2,54.9,25.6
		C813.4,506.2,820,518.5,820,530.3z"
          />
          <path
            style={{
              filter: blur,
              opacity: opacity,
              transition: "filter 2s ease-in-out, opacity 1s ease-in-out",
            }}
            className="st0"
            d="M275.9,389.1c-2.6-6.7-10.9-10.3-18.1-7.7c-10.3,3.1-19.2,8.2-27,15.2c-13.8,12.3-21.3,29.1-21,47.3v25.5v90.8
		c0,7.2,6.3,12.9,14.1,12.9c8,0,14.4-5.7,14.4-12.9v-90.8v-25.5c0-11.6,4-21.6,12.6-29.3c4.9-4.4,10.3-7.5,16.4-9.3
		C274.8,403,278.5,395.5,275.9,389.1z"
          />
          <path
            style={{
              fill: "#a22121",
              opacity: opacity,
              filter: blur,
              transition: "filter 2s ease-in-out, opacity 1s ease-in-out",
            }}
            className="st1"
            d="M334.9,514.3H194.2c-7.9,0-14.2-6.4-14.2-14.2v0c0-7.9,6.4-14.2,14.2-14.2h140.7c7.9,0,14.2,6.4,14.2,14.2v0
		C349.2,507.9,342.8,514.3,334.9,514.3z"
          />
        </g>
      </svg>
    </div>
  );
};

export default MainLoader;
