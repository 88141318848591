import React, { useState, useEffect } from "react";
import "../assets/css/services.css";
import Fade from "react-reveal/Fade";
import Header from "../wrappers/Header";
import Footer from "../wrappers/Footer";
import Loader from "../wrappers/Loader";
import "../assets/css/about.css";
import img1 from "../assets/img/industries/water.png";
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import blogData from "../data/blog.json";
import "../assets/css/blog.css"

const BlogDetailsComponent = () => {
  const languageData = useSelector(state => state.data);
  const currentLanguage = useSelector(state => state.language)

  const [showMenu, setShowMenu] = useState(false);
  const language = useSelector(state => state.language);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const { title, language: x } = useParams();
  useEffect(() => {
    if (x && !["en", "fr", "ru", "tr"].includes(x)) {
      window.location.pathname = `/404`;
    } else if (x && window.location.pathname.endsWith("/")) {
      const newPathname = window.location.pathname.slice(0, -1);
      window.location.pathname = newPathname;
    }
  }, [x]);

  function removeTurkishChars(text) {
    const turkishChars = {
      ğ: "g",
      Ğ: "G",
      ü: "u",
      Ü: "U",
      ş: "s",
      Ş: "S",
      ı: "i",
      İ: "I",
      ö: "o",
      Ö: "O",
      ç: "c",
      Ç: "C",
    };

    return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]);
  }

  const blogsdetail = blogData.blogs.find(
    (blogsData) =>
      blogsData &&
      removeTurkishChars(blogsData.blog_title).replace(/\s+/g, "-").toLowerCase() ===
      title
  );

  if (!blogsdetail) {
    return <p>Blog Bulunamadı.</p>;
  }
  const metaDescriptionTag = document.querySelector(
    'meta[name="description"]'
  );
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute(
        "content",
        ReactHtmlParser(blogsdetail.blog_desc.split('</p>')[0].replace(/<[^>]+>/g, ''))
      );
    
  } else {
    const newMetaTag = document.createElement("meta");
    newMetaTag.name = "description";
    newMetaTag.content = `Focus actuators are mechanisms designed to control the focus of optical components, such as lenses or mirrors, within various technological systems.`;
    document.head.appendChild(newMetaTag);
  }
  const url = `https://www.focusactuators.com/blogdetails/${removeTurkishChars(blogsdetail.blog_title).replace(/\s+/g, "-").toLowerCase()}/${language}`;

  return (
    <>
      <Helmet>
        <title>{blogsdetail.blog_title}</title>
        <meta name="description" content="Water extraction and distribution, as well as wastewater management and cleansing, constitute fundamental necessities for the advancement of infrastructure." />
        <link
          rel="canonical"
          href={url}
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Loader />
      <Fade top delay={800}>
        <div className="downing-page-div">
          <Header showMenu={showMenu} toggleMenu={toggleMenu} />
          <div className="container-fluid text-header d-flex justify-content-center  flex-column align-items-center">
            {!showMenu && (
              <>
                <div className="col-12 title-sticky">
                  <h1 className="title-main-blog" >{blogsdetail.blog_title}</h1>
                </div>
                <div className="mb-5 mt-2">
                  <div className="container d-flex justify-content-center align-items-center flex-column">
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="col-12 light-bg-gray">
                        <div className="text-white w-100">
                          <div className="col-lg-12 col-md-12 col-12 p-3 d-flex justify-content-between flex-column">
                            <div className="col-lg-12 col-md-12 col-12 p-3 d-flex align-items-center justify-content-center blog-main-page">
                              <img className="b_radius h-100" src={blogsdetail.blog_img} alt="focus actuators"
                                height="250"
                                style={{ borderRadius: '10px', border: '5px solid black', width: '50%' }}></img>
                            </div>

                            <div key={blogsdetail.blog_id}>
                              {/* <div className="col-12 mt-5">
                                  <p className="title-services-inner-2">{blogsdetail.blog_title}</p>
                                </div> */}
                              <div className="col-12 bg-gray">
                                <p className="p-0 m-0 text-white">
                                  {ReactHtmlParser(blogsdetail.blog_desc)}
                                  <br />
                                </p>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {!showMenu && <Footer />}
        </div>
      </Fade>
    </>
  );
};

export default BlogDetailsComponent;
