import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentLanguage, setLanguageData } from '../redux/actions/actions';
import englishData from '../Translation/English.json';
import frenchData from '../Translation/French.json';
import russianData from '../Translation/Russian.json';
import turkishData from '../Translation/Turkish.json';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import usaFlag from "../assets/img/usa.png";
import frenchFlag from "../assets/img/france.png";
import russianFlag from "../assets/img/russia.png";
import turkishFlag from "../assets/img/turkiye.png";
import "../assets/css/language.css"

const Language = () => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector(state => state.language);
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      dispatch(setCurrentLanguage(savedLanguage));
      let languageData;
      switch (savedLanguage) {
        case 'fr':
          languageData = frenchData;
          break;
        case 'ru':
          languageData = russianData;
          break;
        case 'tr':
          languageData = turkishData;
          break;
        default:
          languageData = englishData;
      }
      dispatch(setLanguageData(languageData));
    }
  }, [dispatch]);

  const changeLanguage = (language) => {
    let languageData;
    switch (language) {
      case 'fr':
        languageData = frenchData;
        break;
      case 'ru':
        languageData = russianData;
        break;
      case 'tr':
        languageData = turkishData;
        break;
      default:
        languageData = englishData;
    }
    dispatch(setLanguageData(languageData));
    dispatch(setCurrentLanguage(language));
    localStorage.setItem('language', language);
    setSelectedLanguage(language);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getFlag = (language) => {
    switch (language) {
      case 'fr':
        return frenchFlag;
      case 'ru':
        return russianFlag;
      case 'tr':
        return turkishFlag;
      default:
        return usaFlag;
    }
  };

  return (
    <div className='mx-lg-3 mx-md-3 mx-1 mt-1 language-main'>
      <div className="custom-dropdown">
        <button className="btn border custom-dropdown-toggle" onClick={toggleDropdown} type="button">
          <img src={getFlag(selectedLanguage)} alt="Flag" style={{ width: '20px', marginRight: '5px' }} />
          {selectedLanguage.toUpperCase()}
          {dropdownOpen ? (
            <MdOutlineKeyboardArrowDown style={{ marginLeft: '5px' }} />
          ) : (
            <MdOutlineKeyboardArrowRight style={{ marginLeft: '5px' }} />
          )}
        </button>
        {dropdownOpen && (
          <div className="custom-dropdown-menu p-2">
            <div className="dropdown-item" onClick={() => changeLanguage('en')}>
              <img src={usaFlag} alt="USA Flag" style={{ width: '20px', marginRight: '5px' }} /> En
            </div>
            <div className="dropdown-item" onClick={() => changeLanguage('fr')}>
              <img src={frenchFlag} alt="French Flag" style={{ width: '20px', marginRight: '5px' }} /> Fr
            </div>
            <div className="dropdown-item" onClick={() => changeLanguage('ru')}>
              <img src={russianFlag} alt="Russian Flag" style={{ width: '20px', marginRight: '5px' }} /> Ru
            </div>
            <div className="dropdown-item" onClick={() => changeLanguage('tr')}>
              <img src={turkishFlag} alt="Turkish Flag" style={{ width: '20px', marginRight: '5px' }} /> Tr
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Language;
