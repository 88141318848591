import React, { useState, useEffect } from "react";
import "../assets/css/about.css";
import Fade from "react-reveal/Fade";
import Footer from "../wrappers/Footer";
import Loader from "../wrappers/Loader";
import Header from "../wrappers/Header";
import { Helmet } from "react-helmet";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { useSelector } from 'react-redux'
import productsData from "../data/faq.json";
import { useParams } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

const Faq = () => {
  const languageData = useSelector(state => state.data);
  const currentLanguage = useSelector(state => state.language)
  const [showMenu, setShowMenu] = useState(false);
  const [showFooter, setShowFooter] = useState(true);
  const language = useSelector(state => state.language);
  const url = `https://www.focusactuators.com/faq/${language}`;

  const { language: x } = useParams()
  useEffect(() => {
    if (x && !["en", "fr", "ru", "tr"].includes(x)) {
      window.location.pathname = `/404`;
    } else if (x && window.location.pathname.endsWith("/")) {
      const newPathname = window.location.pathname.slice(0, -1);
      window.location.pathname = newPathname;
    }
  }, [x]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    setShowFooter(showMenu);
  };
  
  const transform = (node) => {
    if (node.type === 'tag' && node.name === 'a') {
      return (
        <a  href={node.attribs.href.replace(':language', currentLanguage)}>
          {node.children[0].data}
        </a>
      );
    }
  };
  

  return (
    <>
      <Helmet>
        <title>{languageData.faq}</title>
        <meta name="description" content="You have any specific queries or require further assistance, our team is here to support you every step of the way." />
        <link
          rel="canonical"
          href={url}
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Loader />
      <Fade top delay={800}>
        <div className="downing-page-div">
          <Header showMenu={showMenu} toggleMenu={toggleMenu} />
          <div className="container text-header d-flex justify-content-center  flex-column align-items-center">
            {showFooter && (
              <>
                <div className="col-12 title-sticky">
                  <h1 className="title-main">{languageData.FAQ}</h1>
                </div>
                <div className="bg-main d-flex justify-content-center flex-column align-items-center">
                  <div className="col-lg-9 col-12 bg-gray text-white">
                    <p style={{ fontStyle: "italic" }}>
                      {languageData.faq_text_1}
                    </p>
                  </div>


                  <div className="col-lg-9 col-12 mt-5 mb-5 " >
                    <Accordion preExpanded={["0"]} >
                      {productsData.faqs.map((faq, index) => (
                        <AccordionItem key={faq.faq_no} uuid={`${index}`}>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              {currentLanguage === 'en' ? faq.question_en :
                                (currentLanguage === 'fr' ? faq.question_fr :
                                  (currentLanguage === 'ru' ? faq.question_ru :
                                    (currentLanguage === 'tr' ? faq.question_tr : faq.question)))}
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>{ReactHtmlParser(currentLanguage === 'en' ? faq.answer_en :
                              (currentLanguage === 'fr' ? faq.answer_fr :
                                (currentLanguage === 'ru' ? faq.answer_ru :
                                  (currentLanguage === 'tr' ? faq.answer_tr : faq.answer) )), { transform })}
                                  </p>

                            {faq.button === "true" && (
                              <a
                                rel="noreferrer"
                                aria-label="faq"
                                target="_blank"
                                href={faq.btn_href.endsWith('.pdf') ?
      (language === 'tr' ? `/pdf_tr/${faq.btn_href}` :
       language === 'ru' ? `/pdf_ru/${faq.btn_href}` :
       language === 'fr' ? `/pdf_fr/${faq.btn_href}` :
       `/pdf_en/${faq.btn_href}`) :
      `/${faq.btn_href}/${language}`}

                              >                                  <p className="button-focus mt-2">
                                  {currentLanguage === 'en' ? faq.btn_text_en :
                                    (currentLanguage === 'fr' ? faq.btn_text_fr :
                                      (currentLanguage === 'ru' ? faq.btn_text_ru :
                                        (currentLanguage === 'tr' ? faq.btn_text_tr : faq.btn_text)))}
                                </p>
                              </a>
                            )}
                          </AccordionItemPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </>
            )}
          </div>
          {showFooter && <Footer />}
        </div>
      </Fade>
    </>
  );
};

export default Faq;
