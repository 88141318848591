import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../assets/css/products.css";
import Header from "../wrappers/Header";
import Fade from "react-reveal/Fade";
import Footer from "../wrappers/Footer";
import Loader from "../wrappers/Loader";
import "../assets/css/about.css";
import productsData from "../data/products.json";
import ReactHtmlParser from "react-html-parser";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux'
import { useParams } from "react-router-dom";

const ProductsComponent = () => {
  const languageData = useSelector(state => state.data);
  const currentLanguage = useSelector(state => state.language);
  const [showMenu, setShowMenu] = useState(false);
  // const [activeSlide, setActiveSlide] = useState(0);
  const [pageCanonical, setPageCanonical] = useState("https://www.focusactuators.com/products");
  const [selectedThumbnail, setSelectedThumbnail] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const language = useSelector(state => state.language);
  const { title } = useParams();
  const [activeSlide, setActiveSlide] = useState(0);


  useEffect(() => {
    setPageCanonical(window.location.href); 
  }, [location]);

  // useEffect(() => {
  //   const activeProduct = productsData.find(product => removeTurkishChars(product.title) === title);
  //   if (activeProduct && activeProduct.img_slider) {
  //     setSelectedThumbnail(activeProduct.img_slider[0]);
  //   }
  // }, [title]);

  // useEffect(() => {
   

  //   const activeProduct = productsData[activeSlide];
  //   setSelectedThumbnail(activeProduct.img_slider[0]);
  // }, [activeSlide]);


  useEffect(() => {
    const activeProduct = productsData.find(product => removeTurkishChars(product.title) === title);
    if (activeProduct && activeProduct.img_slider) {
      setSelectedThumbnail(activeProduct.img_slider[0]);
    }
  }, [title]);



  const products = productsData.find(
    (proData) =>
      removeTurkishChars(proData.title).replace(/\s+/g, "-").toLowerCase() ===
      title
  );

  useEffect(() => {
    if (products && products.img_slider) {
      setSelectedThumbnail(products.img_slider[0]);
    }
  }, [products]);


  const handleThumbnailClick = (newSelection) => {
    setSelectedThumbnail(newSelection);
  };

  function removeTurkishChars(text) {
    const turkishChars = {
      ğ: "g",
      Ğ: "G",
      ü: "u",
      Ü: "U",
      ş: "s",
      Ş: "S",
      ı: "i",
      İ: "I",
      ö: "o",
      Ö: "O",
      ç: "c",
      Ç: "C",
    };

    return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, (match) => turkishChars[match]);
  }

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    centerMode: true,
    focusOnSelect: true,
    swipeToSlide: true,

    responsive: [
      {
        breakpoint: 2700,
        settings: {
          slidesToShow: 3,
          centerPadding: "580px",
        },
      },
      {
        breakpoint: 1540,
        settings: {
          slidesToShow: 3,
          centerPadding: "180px",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          centerPadding: "10px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 1,
          centerPadding: "100px",
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>{currentLanguage === 'en' ? products.title :
                        (currentLanguage === 'fr' ? products.title_fr :
                          (currentLanguage === 'ru' ? products.title_ru :
                            (currentLanguage === 'tr' ? products.title_tr : products.title)))}</title>
        <meta
          name="description"
          content= {products
            ? products.seo_desc
            : "Multi-turn electric actuators are devices used to control the position of valves or other mechanisms that require multiple rotations to open or close fully."}
        />
        <link
          rel="canonical"
          href={pageCanonical}

        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <Loader />
      <Fade top delay={800}>
        <div className="downing-page-div" >
          <Header showMenu={showMenu} toggleMenu={toggleMenu} />
          <div className="text-header  products-page" >
            {!showMenu && (
              <>
           
                <div className="products-page-inner">
                  {" "}
                  <div
                    className="d-flex flex-column align-items-center justify-content-center title-main"
                  >

                    <h1 className="title-main m-0 p-0">
                       
                      
                      <b>{currentLanguage === 'en' ? products.title :
                        (currentLanguage === 'fr' ? products.title_fr :
                          (currentLanguage === 'ru' ? products.title_ru :
                            (currentLanguage === 'tr' ? products.title_tr : products.title)))}</b>
                    </h1>
                    <h2 className="text-start m-0 p-0 text-center">
                    {currentLanguage === 'en' ? products.title_inner :
                        (currentLanguage === 'fr' ? products.title_inner_fr :
                          (currentLanguage === 'ru' ? products.title_inner_ru :
                            (currentLanguage === 'tr' ? products.title_inner_tr : products.title_inner)))}

                    </h2>
                  </div>



                  <div className="container text-black col-lg-9 products-page-desc d-flex justify-content-center align-items-center flex-column p-lg-0 p-4 overflow-hidden mt-3">
                    <img
                      src={products.img}
                      alt={currentLanguage === 'en' ? products.title :
                        (currentLanguage === 'fr' ? products.title_fr :
                          (currentLanguage === 'ru' ? products.title_ru :
                            (currentLanguage === 'tr' ? products.title_tr : products.title)))}
                      alt1={currentLanguage === 'en' ? products.title :
                        (currentLanguage === 'fr' ? products.title_fr :
                          (currentLanguage === 'ru' ? products.title_ru :
                            (currentLanguage === 'tr' ? products.title_tr : products.title)))}
                    // {currentLanguage === 'en' ? product.title_inner : (currentLanguage === 'fr' ? product.title_inner_fr : product.title_inner_ru)}
                    ></img>

                    <p className="title-main ">
                      {currentLanguage === 'en' ? products.title.substring(5) :
                        (currentLanguage === 'fr' ? products.title_fr.substring(5) :
                          (currentLanguage === 'ru' ? products.title_ru.substring(5) :
                            (currentLanguage === 'tr' ? products.title_tr.substring(5) : products.title.substring(5))))}

                    </p>
                    <h3 className="text-black text-center">
                      <b>{currentLanguage === 'en' ? products.title_inner :
                        (currentLanguage === 'fr' ? products.title_inner_fr :
                          (currentLanguage === 'ru' ? products.title_inner_ru :
                            (currentLanguage === 'tr' ? products.title_inner_tr : products.title_inner)))}</b>

                    </h3>

                    <div className="d-lg-flex gap-5 mt-3">
                      <div className="col-lg-6 col-12">
                        <p
                          className="text-black"
                          style={{ textAlign: "justify" }}
                        >
                          {currentLanguage === 'en' ? products.desc :
                            (currentLanguage === 'fr' ? products.desc_fr :
                              (currentLanguage === 'ru' ? products.desc_ru :
                                (currentLanguage === 'tr' ? products.desc_tr : products.desc)))}
                        </p>
                      </div>
                      <div className="col-lg-6 col-12 d-lg-flex text-black gap-4 m-lg-0 m-3">


                        <div
                          dangerouslySetInnerHTML={{
                            __html: currentLanguage === 'en' ? products.att :
                              (currentLanguage === 'fr' ? products.att_fr :
                                (currentLanguage === 'ru' ? products.att_ru :
                                  (currentLanguage === 'tr' ? products.att_tr : products.att)))
                          }}

                        />

                        <div
                          dangerouslySetInnerHTML={{
                            __html: currentLanguage === 'en' ? products.att2 :
                              (currentLanguage === 'fr' ? products.att2_fr :
                                (currentLanguage === 'ru' ? products.att2_ru :
                                  (currentLanguage === 'tr' ? products.att2_tr : products.att2)))
                          }}

                        />
                      </div>
                    </div>

                    <h3 className="optional_text">
                      <b>{currentLanguage == 'en' ? products.op_1 :
                        (currentLanguage === 'fr' ? products.op_1_fr :
                          (currentLanguage === 'ru' ? products.op_1_ru :
                            (currentLanguage === 'tr' ? products.op_1_tr : products.op_1)))}</b>
                    </h3>
                    <div
                      className="col-12 products_op d-flex flex-wrap justify-content-around light-bg-gray m-0 p-2 align-items-center text-center"
                      style={{ listStyle: "none" }}
                    >
                      <div className="col-12 col-lg-3 col-md-3 p-2 vertical-line">
                        <li className="m-0">
                          {ReactHtmlParser(currentLanguage === 'en' ? products.op_1_alt :
                            (currentLanguage === 'fr' ? products.op_1_alt_fr :
                              (currentLanguage === 'ru' ? products.op_1_alt_ru :
                                (currentLanguage === 'tr' ? products.op_1_alt_tr : products.op_1_alt))))}
                        </li>
                      </div>
                      <div className="col-12 col-lg-3 p-2 col-md-3 vertical-line">
                        <li className="m-0">
                          {ReactHtmlParser(currentLanguage === 'en' ? products.op_2_alt :
                            (currentLanguage === 'fr' ? products.op_2_alt_fr :
                              (currentLanguage === 'ru' ? products.op_2_alt_ru :
                                (currentLanguage === 'tr' ? products.op_2_alt_tr : products.op_2_alt))))}
                        </li>
                      </div>
                      <div className="col-12 col-lg-3 col-md-3 p-2 vertical-line">
                        <li className="m-0 ">
                          {ReactHtmlParser(currentLanguage === 'en' ? products.op_3_alt :
                            (currentLanguage === 'fr' ? products.op_3_alt_fr :
                              (currentLanguage === 'ru' ? products.op_3_alt_ru :
                                (currentLanguage === 'tr' ? products.op_3_alt_tr : products.op_3_alt))))}
                        </li>
                      </div>
                      <div className="col-12 col-lg-3 col-md-3 p-2 ">
                        <li className="m-0 ">
                          {ReactHtmlParser(currentLanguage === 'en' ? products.op_4_alt :
                            (currentLanguage === 'fr' ? products.op_4_alt_fr :
                              (currentLanguage === 'ru' ? products.op_4_alt_ru :
                                (currentLanguage === 'tr' ? products.op_4_alt_tr : products.op_4_alt))))}
                        </li>
                      </div>
                    </div>
                    <div className="d-lg-flex col-12 mt-4 mb-4  justify-content-between">
                      <div className="col-lg-6 col-12  gap-lg-4 gap-1">
                        <div className="gallery-products d-flex gap-lg-4 gap-2 justify-content-between">
                        <div className="thumbnails col-lg-3 col-md-3 col-3 d-flex flex-column justify-content-between gap-2 h-100">
                            {products.img_slider.map(
                              (image, index) => (
                                <a
                                  href="#"
                                  key={index}
                                  className={
                                    selectedThumbnail === image
                                      ? "selected thumbnail"
                                      : "thumbnail"
                                  }
                                  onClick={() => handleThumbnailClick(image)}
                                >
                                  <div className="col-lg-12 col-9 thumbnail-image w-100 p-2">
                                    <img  src={image}></img>
                                  </div>
                                </a>
                              )
                            )}
                          </div>
                          <div className="col-9 brochure primary p-3">
                            <img src={selectedThumbnail}></img>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5  brochure d-flex justify-content-center align-items-center flex-column p-3 mt-lg-0 mt-4">
                        <img
                          className="products-brochure"
                          src={products.brochure_img}
                        ></img>
                        <h3 className="text-center m-3" >
                          <b>{currentLanguage === 'en' ? products.download_text :
                            (currentLanguage === 'fr' ? products.download_text_fr :
                              (currentLanguage === 'ru' ? products.download_text_ru :
                                (currentLanguage === 'tr' ? products.download_text_tr : products.download_text)))}
                          </b>
                        </h3>
                        <a
                          target="_blank"
                          href={products.download.endsWith('.pdf') ?
                          (language === 'tr' ? `/pdf_tr/${products.download}` :
                           language === 'ru' ? `/pdf_ru/${products.download}` :
                           language === 'fr' ? `/pdf_fr/${products.download}` :
                           `/pdf_en/${products.download}`) :
                          `/pdf_${language}/${products.download}`}
                    
                          aria-label="become-disc"
                        >

                          <p className="button-focus m-0 ">{languageData.download}</p>
                         
                        </a>
                      </div>
                    </div>

                  </div>
                 
                </div>
              </>
            )}
          </div>
          {!showMenu && <Footer />}
        </div>
      </Fade>
    </>
  );
};

export default ProductsComponent;
