import React, { useState, useEffect } from "react";
import "../assets/css/menu.css";
import Fade from "react-reveal/Fade";
import menu_img from "../assets/img/menu_img.png";
import { useSelector } from 'react-redux'
import Language from "./Language";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
const Menu = ({ show, toggleMenu }) => {
  const languageData = useSelector(state => state.data);
  const language = useSelector(state => state.language);
  const [isExpanded, setIsExpanded] = useState(true);

  const [imgVisible, setImgVisible] = useState(false);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCollapse = () => {
    setIsExpanded(false);
  };

  useEffect(() => {
    if (show) {
      const timeout = setTimeout(() => {
        setImgVisible(true);
      }, 800);
      return () => clearTimeout(timeout);
    } else {
      setImgVisible(false);
    }
  }, [show]);
  return (
    <>
      <div className="menu-btn mt-4 d-flex justify-content-end">
        <span
          onClick={toggleMenu}
          className="menu-btn-content"
          data-hover={show ? languageData.close : languageData.menu}
        >
          {show ? languageData.close : languageData.menu}
        </span>
      </div>
      <div
        className="justify-content-center"
        id="menu-show"
        style={{ display: show ? "flex" : "none" }}
      >
        <div className="d-flex justify-content-center align-items-center">
          <Fade top when={show}>
            <div className={`w-100 menu-content `}>
              <div className="container d-lg-flex menu-height align-items-center">
                <div className="col-lg-5 col-12 mt-lg-5 mt-2">
                  <div className="menu-content-body-list text-white">

                    <ul>
                      <li>
                        <a title={languageData.home} href={`/${language}`} aria-label="home-menu"> {languageData.home}</a>
                      </li>
                      <li>
                        <a title={languageData.why_focus} href={`/why_focus/${language}`} aria-label="why-focus-menu"> {languageData.why_focus}</a>
                      </li>

                      <li>
                        <a title={languageData.about_focus} href={`/about_focus/${language}`} aria-label="about-menu"> {languageData.about_focus}</a>
                      </li>

                      <li>
                        <a title={languageData.products} href={`/products/${language}`} aria-label="productdetail">
                          {languageData.products}
                        </a>
                        {isExpanded ? (
                          <MdOutlineKeyboardArrowDown onClick={handleCollapse} />
                        ) : (
                          <MdOutlineKeyboardArrowRight onClick={handleExpand} />
                        )}
                        {isExpanded && (
                          <ul className="m-2" >
                            <li ><a title={languageData.multiturn_electric_actuators} href={`/multi-turn_electric_actuators/${language}`}>{languageData.multiturn_electric_actuators}</a></li>
                            <li><a title={languageData.quarterturn_electric_actuators} href={`/quarter-turn_electric_actuators/${language}`}>{languageData.quarterturn_electric_actuators}</a></li>
                            <li><a title={languageData.gearboxes} href={`/bevel-gearboxes/${language}`}>{languageData.gearboxes}</a></li>
                          </ul>
                        )}
                      </li>

                      <li>
                        <a title={languageData.industries} href={`/industries/${language}`} aria-label="ind-menu">{languageData.industries}</a>
                      </li>

                      {/* <li>
  <a href={`/products/focus-e-series?products=0/${language}`} aria-label="products-menu">
    {languageData.products}
  </a>
</li> */}

                      <li>
                        <a title={languageData.service_support} href={`/service_support/${language}`} aria-label="service-menu">  {languageData.service_support}</a>
                      </li>

                      <li>
                        <a title={languageData.FAQ} href={`/faq/${language}`} aria-label="faq-menu">{languageData.FAQ}</a>
                      </li>

                      <li>
                        <a title={languageData.documents_2} href={`/documents/${language}`} aria-label="docs-menu"> {languageData.documents_2}</a>
                      </li>

                      <li>
                        <a title={languageData.human_resources} href={`/human_resources/${language}`} aria-label="human-menu">  {languageData.human_resources}</a>
                      </li>

                      <li>
                        <a title={languageData.become_a_distributor} href={`/become_a_distributor/${language}`} aria-label="become-menu">{languageData.become_a_distributor}</a>
                      </li>

                      <li>
                        <a title={languageData.news_menu} href={`/news/${language}`} aria-label="become-menu">{languageData.news_menu}</a>
                      </li>

                      <li>
                        <a title={languageData.blog_menu} href={`/blog/${language}`} aria-label="become-menu">{languageData.blog_menu}</a>
                      </li>

                      <li>
                  
                  <a title={languageData.termsandconditional} href={`/salesterms/${language}`} aria-label="sales-menu">{languageData.termsandconditional}</a>
                </li>

                      <li>
                        <a title={languageData.contact_2} href={`/contact/${language}`} aria-label="contact-menu"> {languageData.contact_2}</a>
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="col-lg-7 col-12 ">
                  <img
                    src={menu_img}
                    alt="menu image"
                    style={{
                      opacity: imgVisible ? 1 : 0,
                      transition: "opacity 2s ease-in-out",
                    }}
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
};

export default Menu;
